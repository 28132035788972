import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  error: false,
  message:""
};

const fileErrors = createSlice({
  name: "inputFileErrors",
  initialState,
  reducers: {
    onUpdateError: (state, action: any) => {
        return { ...state, message: action.payload, error:true };
  }, onResetFileErrors: (state) => {
    return { ...state, message:"", error:false };
}}});

export const { onUpdateError,onResetFileErrors } = fileErrors.actions;

export default fileErrors.reducer;