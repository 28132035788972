import { ReactElement } from "react";
import { Container, Row } from "react-bootstrap";
import { ReactComponent as EmptyMoon } from "../../assets/img/emptyMoon.svg";
import Button from "../ui/Button/Button";
import styles from "./ErrorPage.module.scss";

interface Props {
  children?: ReactElement;
}

const ErrorPage = ({ children }: Props) => {
  return (
    <Container className={styles.errorContainer}>
      <EmptyMoon />
      <h1>Lo sentimos, algo falló inesperadamente.</h1>
      <p className={styles.text}>
        Por favor, intentá cargar la página nuevamente o contactate con soporte
      </p>
      <div>
        <Button
          click={() => window.location.reload()}
          buttonType="primary"
          text="Reintentar"
          type="button"
        />
        <Button
          click={() => window.open("https://wa.me/5491138499171", "_blank")}
          buttonType="secondary"
          text="Contactar con soporte"
          type="button"
        />
      </div>
    </Container>
  );
};

export default ErrorPage;
