import { ReactElement } from "react";
import { toast, ToastOptions } from "react-toastify";

interface AddMessageProps {
    message: string | ReactElement;
    messageId: string;
    type?: "success" | "error" | "info" | "warning"; 
    autoClose?: number | false;
    keepOpen?: boolean;
    hideProgressBar?: boolean;
    closeOnClick?: boolean;
    dragable?: boolean;
    closeButton?: boolean;
    showOverPage?: boolean;
    position?: "top-right" | "top-center" | "top-left" | "bottom-right" | "bottom-center" | "bottom-left";
    onClose?: () => void;
    onOpen?: () => void;
    clearWaitingQueue?: boolean;
}

export const addMessage = (props : AddMessageProps ) => {
  const { message, messageId, autoClose, type, hideProgressBar, keepOpen, closeOnClick, closeButton, dragable, showOverPage, position, onClose, onOpen, clearWaitingQueue } = props;

  const options: ToastOptions  = {
    containerId: showOverPage ? `${messageId}-overPage` : messageId,
    autoClose: keepOpen ? false : autoClose,
    type: type || "success",
    hideProgressBar: keepOpen ? true : hideProgressBar,
    closeOnClick: keepOpen ? false : closeOnClick,
    draggable: keepOpen ? false : dragable,
    closeButton: closeButton,
    position: position || "top-center",
    onClose,
    onOpen,    
    bodyClassName:"text-dark"
  };
  clearWaitingQueue ?? toast.clearWaitingQueue({ containerId: showOverPage ? `${messageId}-overPage` : messageId });
  return toast(message, options);
};