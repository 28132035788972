import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modalOpen: false,
  modalName: ""
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    closeModal: (state) => {
      state.modalOpen = false;
      state.modalName = "";
    },
    openModal: (state, action) => {
      state.modalOpen = true;
      state.modalName = action.payload.name;
    },
    changeModalName: (state, action ) => {
      state.modalName = action.payload.name;
    },
  }
});

export const { closeModal, openModal, changeModalName } = modalSlice.actions;

export default modalSlice.reducer;