import Form from "react-bootstrap/Form";
import { useField } from "formik";
import styles from "./Inputs.module.scss";
import { InputError } from "./UtilsInputs";
import { useEffect, useState } from "react";

interface InputTextProps {
  name: string;
  label?: string;
  type: "text" | "number";
  placeholder?: string;
  secondLabel?: React.ReactNode;
  labelcallback?: () => void;
  disabled?: boolean;
  validateOnChange?: boolean;
  maxLength?: number;
  onChange?: (e: any) => void;
}

const InputTextNumber = (props: InputTextProps) => {
  const {
    name,
    label,
    secondLabel,
    placeholder,
    labelcallback,
    validateOnChange,
    onChange,
    ...rest
  } = props;
  const { FormGroupInputText, LabelClickeable } = styles;
  const [field, meta, helpers] = useField(props);
  const [showError, setShowError] = useState(false);

  const handleChange = async (e: React.ChangeEvent) => {
    field.onChange(e);
    if (onChange) onChange(e);
    setShowError(false);
  };
  useEffect(() => {
    helpers.setTouched(true);
    let timer: NodeJS.Timeout;
    timer = setTimeout(() => setShowError(true), 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [field.value]);
  useEffect(() => {
    if (field.value === "") helpers.setTouched(false);
  }, []);

  return (
    <Form.Group
      controlId={name}
      className={`${FormGroupInputText} custom-form-group`}
    >
      {label && (
        <Form.Label>
          {label}
          {secondLabel && (
            <span>
              {" "}
              -{" "}
              <span className={LabelClickeable} onClick={labelcallback}>
                {secondLabel}
              </span>
            </span>
          )}
        </Form.Label>
      )}
      <Form.Control
        {...field}
        {...rest}
        onChange={handleChange}
        placeholder={placeholder}
        isInvalid={!!meta.touched && !!meta.error}
        isValid={
          !!meta.touched === true && !!meta.error === false && meta.value !== ""
            ? true
            : false
        }
      />
      <InputError
        touched={showError ? meta.touched : false}
        error={meta.error}
      />
    </Form.Group>
  );
};

export default InputTextNumber;
