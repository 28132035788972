export const livenessTexts = {
  es: {
    waitingCameraPermissionText: "Esperando el acceso a tu cámara",
    startScreenBeginCheckText: "Comenzar captura",
    hintConnectingText: "Conectando...",
    hintVerifyingText: "Verificando...",
    hintCheckCompleteText: "Completado",
    goodFitCaptionText: "Mantené tu posición",
    goodFitAltText: "Encajá perfectamente en el óvalo",
    hintTooCloseText: "Alejate",
    hintTooFarText: "Acercate",
    hintCenterFaceText: "Centrá tu cara",
    hintFaceOffCenterText: "Centrá tu cara",
    hintMoveFaceFrontOfCameraText: "Movete enfrente de la cámara",
    hintTooManyFacesText: "Asegurate que haya solo una cara",
    hintFaceDetectedText: "Cara detectada",
    hintCanNotIdentifyText: "Movete enfrente de la cámara",
    hintIlluminationTooDarkText: "Buscá un lugar con mejor iluminación",
    hintIlluminationTooBright: "Buscá un lugar con menos luz",
    hintHoldFaceForFreshnessText: "Mantené tu posición",
    hintMatchIndicatorText: "50% completado. Acercate",
    tryAgainText: "Reintentar",
    retryCameraPermissionsText: "Reintentar",
    cameraNotFoundHeadingText: "Permití el acceso a tu cámara",
  },
};
