import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  width: window.innerWidth,
};

export const windowWidthReducer = createSlice({
  name: "window width",
  initialState,
  reducers: {
    screenResize: (state, action: PayloadAction<number> ) => {
      state.width = action.payload;
    }
  }
});

export const { screenResize } = windowWidthReducer.actions;

export default windowWidthReducer.reducer; 