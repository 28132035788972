import { Col, Row } from "react-bootstrap";
import InputTextNumber from "../../../ui/Inputs/InputTextNumber";
import InputSelect from "../../../ui/Inputs/InputSelect";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { openModal } from "../../../../reducers/modalReducer";
import styles from "../Steps.module.scss";
import InputFile from "../../../ui/Inputs/InputFile";
import { onUpdateStepNoAllariaAccount } from "../../../../reducers/accountOpeningForm";
import { useEffect } from "react";
import { stepThreePart1Schema } from "../../../../schemas/steps";
import FormikWrapper from "../../../ui/FormikWrapper/FormikWrapper";
import StepButtons from "../../../ui/StepButtons/StepButtons";
import { useRef, useState } from "react";
import {
  saveDocumentData,
  updateUserProfile,
  uploadPhoto,
  validateRenaperData,
} from "../../../../connectors/connectors";
import { renameKeys, toastifyError } from "./utils";
import { genericError } from "../../../utils/utils";
import Tooltip from "../../../ui/Tooltip/Tooltip";
import infoBlueIcon from "../../../../assets/img/Inputs/infoBlueIcon.svg";
import QRCode from "qrcode";
import { doc, onSnapshot, getFirestore, setDoc } from "firebase/firestore";
import { getStorage, ref, getBlob } from "firebase/storage";
import { FormikProps, FormikValues } from "formik";
import {
  FirebaseUserImage,
  FirebaseUserImageState,
  DNIImageType,
} from "../../../../interfaces/types";

interface StepThreePart1Values {
  dni: number | "" | undefined;
  processNumber: number | "" | undefined;
  gender: string;
  maritalStatus: string;
  frontDNI: any | null | number;
  backDNI: any | null | number;
}

interface TooltipAndExampleProps {
  tooltip: JSX.Element;
  modalName: "modalDNI";
}

const StepThreePart1 = () => {
  const { fileErrors, windowWidth } = useAppSelector((state) => state);
  const isMobile = windowWidth.width < 768;

  const { formWithoutAllariaAccount, token } = useAppSelector(
    (state) => state.accountOpeningForm
  );

  const dispatch = useAppDispatch();
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const formikForm = useRef<FormikProps<FormikValues>>(null);

  const [qrImage, setQrImage] = useState("");

  const [firebaseFiles, setFirebaseFiles] = useState<FirebaseUserImageState>({
    frontDNI: {
      uploaded: undefined,
      storageFullPath: undefined,
      file: undefined,
      originalFileName: undefined,
    },
    backDNI: {
      uploaded: undefined,
      storageFullPath: undefined,
      file: undefined,
      originalFileName: undefined,
    },
  });

  const db = getFirestore();
  const storage = getStorage();

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "userImages", token), (doc) => {
      if (doc.exists()) {
        const data = doc.data() as FirebaseUserImage;
        Object.keys(data).forEach((dniImage) => {
          const imageDNI = data[dniImage as DNIImageType];
          const imageRef = ref(storage, imageDNI.storageFullPath);

          if (imageDNI.storageFullPath) {
            getBlob(imageRef)
              .then((blob) => {
                const blobToFile = new File(
                  [blob],
                  imageDNI.originalFileName || "",
                  {
                    type: blob.type,
                  }
                );
                setFirebaseFiles((prevState) => ({
                  ...prevState,
                  [dniImage]: {
                    ...imageDNI,
                    file: blobToFile,
                  },
                }));
                if (formikForm?.current) {
                  formikForm.current.setFieldValue(dniImage, blobToFile);
                  formikForm.current.setFieldTouched(dniImage, true);
                }
              })
              .catch((err) => {
                console.log("err", err);
              });
          }
        });
      } else {
        createInitialData();
      }
    });
    return unsub;
  }, []);

  const createInitialData = () => {
    const imageRef = doc(db, "userImages", token || "");
    setDoc(imageRef, {
      backDNI: {
        uploaded: false,
        storageFullPath: "",
        originalFileName: "",
      },
      frontDNI: {
        uploaded: false,
        storageFullPath: "",
        originalFileName: "",
      },
    });
  };

  useEffect(() => {
    if (!isMobile) {
      QRCode.toDataURL(
        `${window.location.origin}/dni-upload?token=${token}`,
        {
          color: {
            dark: "#014092",
          },
          margin: 0,
          width: 80,
        }
      ).then(setQrImage);
    }
  }, [isMobile]);

  const { userReferred, emailOrNameReferred } = formWithoutAllariaAccount[1];
  const {
    DNI,
    gender,
    maritalStatus,
    frontDNI,
    backDNI,
    cuit,
    address,
    politicallyExposedPerson,
    telephoneNumber,
  } = formWithoutAllariaAccount[3];

  const initialValues: StepThreePart1Values = {
    dni: DNI.number || "",
    processNumber: DNI.processNumber || "",
    gender,
    maritalStatus,
    frontDNI: frontDNI || null,
    backDNI: backDNI || null,
  };

  const newKeys = [
    {
      from: "lastNames",
      to: "last_names",
    },
    {
      from: "number",
      to: "number_id",
    },
    {
      from: "expirationDate",
      to: "expiration_date",
    },
    {
      from: "creationDate",
      to: "creation_date",
    },
    {
      from: "streetAddress",
      to: "street_address",
    },
    {
      from: "numberStreet",
      to: "street_number",
    },
    {
      from: "zipCode",
      to: "postal_code",
    },
    {
      from: "countryBirth",
      to: "country_birth",
    },
    {
      from: "messageOfDeath",
      to: "message_of_death",
    },
  ];

  const handleSubmit = async (values: StepThreePart1Values) => {
    const { dni, processNumber, gender, maritalStatus, frontDNI, backDNI } =
      values;

    setLoadingButton(true);
    let personData;
    const firstLetterGender = gender[0];
    try {
      const renaperData = await validateRenaperData(
        dni,
        processNumber,
        firstLetterGender,
        token
      );

      const { code, person, valid } = renaperData?.data;

      const renaperDataValid =
        renaperData && code === 10001 && valid === "Vigente";

      // If renaper data validation is successful continue
      if (renaperDataValid) {
        personData = JSON.parse(person);
        personData.valid = true;
        const info = {
          ...formWithoutAllariaAccount[3],
          currentStepProgress: 2,
          DNI: {
            number: dni || null,
            processNumber: processNumber || null,
          },
          gender,
          maritalStatus,
          frontDNI: frontDNI ? 1 : null,
          backDNI: backDNI ? 1 : null,
          firstName: personData.names,
          lastName: personData.lastNames,
          dateOfBirth: personData.birthdate || "",
          nationality: personData.nationality || "",
          cuit: cuit || personData.cuil || "",
          telephoneNumber: telephoneNumber || "",
          politicallyExposedPerson: politicallyExposedPerson || "",
          address: {
            street: address.street || personData.streetAddress || "",
            streetNumber: address.streetNumber || personData.numberStreet || "",
            floorNumber: address.floorNumber || personData.floor || "",
            department: address.department || personData.department || "",
            zipCode: address.zipCode || personData.zipCode || "",
            locality: address.locality || personData.city || "",
            province: address.province || personData.province || "",
            country:
              address.country ||
              personData.country ||
              personData.countryBirth ||
              "",
          },
        };
        const userProfile = {
          screen_spot: 3,
          marital_status: maritalStatus,
          document_type: "DNI",
          document_procedure_id: processNumber,
          sex: gender,
          was_referred: userReferred,
          chosen_referral: emailOrNameReferred,
        };
        await updateUserProfile(token, userProfile);
        if (typeof values.backDNI !== "number")
          await uploadPhoto(values.backDNI, "BACK", token);
        if (typeof values.frontDNI !== "number")
          await uploadPhoto(values.frontDNI, "FRONT", token);
        let newPersonData = structuredClone(personData);
        newKeys.map((item) => renameKeys(newPersonData, item.from, item.to));
        await saveDocumentData(token, newPersonData);
        dispatch(onUpdateStepNoAllariaAccount({ step: 3, data: info }));
      } else {
        if (code === 10001 && valid === "No vigente")
          toastifyError(
            "El número de tramite no corresponde al DNI ingresado."
          );
        else if (code === 10008)
          toastifyError("El género no corresponde al DNI ingresado.");
        else if (code === 1000) toastifyError("Datos no ingresados.");
        else
          toastifyError(
            "No pudimos validar tus datos. Por favor intentalo nuevamente."
          );
      }
    } catch (error: any) {
      genericError();
    } finally {
      setLoadingButton(false);
    }
  };

  useEffect(() => {
    if (fileErrors?.message !== "") toastifyError(fileErrors?.message);
  }, [fileErrors?.message]);

  useEffect(() => {
    const fieldsTouchedToTrue = ["gender", "maritalStatus"];
    fieldsTouchedToTrue.forEach((field) =>
      formikForm.current?.values[field] !== ""
        ? formikForm.current?.setFieldTouched(field, true)
        : null
    );
  }, []);

  const TooltipAndExample = ({
    tooltip,
    modalName,
  }: TooltipAndExampleProps) => {
    return (
      <div className={styles.tooltipAndExample}>
        {tooltip}
        <span onClick={() => dispatch(openModal({ name: modalName }))}>
          {" "}
          - Ver ejemplo
        </span>
      </div>
    );
  };

  return (
    <div className={styles.stepThree}>
      <FormikWrapper
        initialValues={initialValues}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        validationSchema={stepThreePart1Schema}
        innerRef={formikForm}
      >
        {({ errors, handleSubmit, values }) => (
          <form>
            <Row className="align-items-end">
              <Col md className="mb-3 mt-3">
                <InputTextNumber
                  label="DNI"
                  name="dni"
                  placeholder="Sin puntos"
                  type="number"
                />
              </Col>
              <Col md className="mb-3">
                <InputTextNumber
                  label="Número de trámite"
                  secondLabel={
                    <span className={styles.secondLabel}>
                      ¿Dónde lo encuentro?
                    </span>
                  }
                  labelcallback={() =>
                    dispatch(openModal({ name: "modalProcessNumber" }))
                  }
                  name="processNumber"
                  placeholder="00000000000"
                  type="text"
                />
              </Col>
            </Row>
            <Row className={`mb-4 ${styles.contact}`}>
              <span>
                Si no posees DNI argentino contactate con{" "}
                <b>hola@allariamas.com.ar</b>
              </span>
            </Row>
            <Row>
              <Col md className="mb-3">
                <InputSelect
                  label="Género"
                  name="gender"
                  placeholder="Seleccioná una opción"
                  options={[
                    { value: "MASCULINE", label: "Masculino" },
                    { value: "FEMALE", label: "Femenino" },
                    {
                      value: "X",
                      label: (
                        <>
                          X <span className="text-gray ">- No Binario</span>
                        </>
                      ),
                    },
                  ]}
                />
              </Col>
              <Col md className="mb-4">
                <InputSelect
                  label="Estado civil"
                  name="maritalStatus"
                  placeholder="Seleccioná una opción"
                  options={[
                    { value: "SOLTERO", label: "Soltero/a" },
                    { value: "CASADO", label: "Casado/a" },
                    { value: "VIUDO", label: "Viudo/a" },
                    { value: "DIVORCIADO", label: "Divorciado/a" },
                  ]}
                />
              </Col>
            </Row>
            {!isMobile && (
              <div className={styles.QRContainer}>
                <img src={qrImage} alt="QR" />
                <p>Escaneá el código y subí las fotos desde tu teléfono</p>
              </div>
            )}

            <div className={styles.subTitleContainer}>
              <h3>Cargá las fotos de tu DNI </h3>
              <TooltipAndExample
                tooltip={<TooltipDNI />}
                modalName="modalDNI"
              />
            </div>
            <div className={`${styles.dniWrapper} mb-5`}>
              <Row className={styles.dniRow}>
                <Col md>
                  <label className={styles.label}>Frente</label>
                  <InputFile
                    name="frontDNI"
                    token={token}
                    uploadedFile={
                      firebaseFiles.frontDNI?.uploaded
                        ? firebaseFiles.frontDNI?.file
                        : null
                    }
                  />
                </Col>
                <Col md>
                  <label className={styles.label}>Dorso</label>
                  <InputFile
                    name="backDNI"
                    token={token}
                    uploadedFile={
                      firebaseFiles.backDNI?.uploaded
                        ? firebaseFiles.backDNI?.file
                        : null
                    }
                  />
                </Col>
              </Row>
            </div>
            <StepButtons
              type="button"
              text="Siguiente"
              disabled={
                Object.keys(errors).length > 0 ||
                !Object.values(values).some((value) => !!value) ||
                loadingButton
              }
              onClick={() => handleSubmit()}
              isLoading={loadingButton}
            />
          </form>
        )}
      </FormikWrapper>
    </div>
  );
};

export default StepThreePart1;

const TooltipDNI = () => (
  <Tooltip text={"No es válido el DNI digital"} position={"top"} interactive>
    <img src={infoBlueIcon} alt="icon-tooltip" />
  </Tooltip>
);
