import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import StepButtons from "../../../ui/StepButtons/StepButtons";
import LivenessValidationCard from "./LivenessValidationCard/LivenessValidationCard";
import { onUpdateStepNoAllariaAccount } from "../../../../reducers/accountOpeningForm";
import { FirebaseUserLivenessValidation } from "../../../../interfaces/types";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { isDataUndefined, saveToFirestoreLiveness } from "./utils";

const StepThreePart2 = () => {
  const { token, formWithoutAllariaAccount } = useAppSelector(
    (state) => state.accountOpeningForm
  );

  const { email } = formWithoutAllariaAccount[1];

  const [firebaseLivenessValidation, setFirebaseLivenessValidation] =
    useState<FirebaseUserLivenessValidation>({
      email: email,
      isLivenessValid: false,
      attemptsDesktop: 0,
      attemptsMobile: 0,
      scannedQR: false,
    });

  const { isLivenessValid } = firebaseLivenessValidation;
  const db = getFirestore();

  const dispatch = useAppDispatch();

  const handleSubmit = () => {
    const info = {
      ...formWithoutAllariaAccount[3],
      currentStepProgress: 3,
    };

    dispatch(onUpdateStepNoAllariaAccount({ step: 3, data: info }));
  };

  useEffect(() => {
    const unsub = onSnapshot(
      doc(db, "userLivenessValidation", token),
      (doc) => {
        if (doc.exists()) {
          const data = doc.data() as FirebaseUserLivenessValidation;
          if (!isDataUndefined(data)) {
            setFirebaseLivenessValidation(data);
          } else {
            saveToFirestoreLiveness({
              ...firebaseLivenessValidation,
              ...data,
              token,
            });
          }
        } else {
          saveToFirestoreLiveness({
            ...firebaseLivenessValidation,
            token,
          });
        }
      }
    );

    return unsub;
  }, []);

  return (
    <div>
      <LivenessValidationCard token={token} />
      <StepButtons
        type="button"
        text="Siguiente"
        disabled={!isLivenessValid}
        onClick={() => handleSubmit()}
      />
    </div>
  );
};

export default StepThreePart2;
