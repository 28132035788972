import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  onUpdateCompletedForm,
  onUpdateCurrentStep,
  onUpdateToken,
  onUpdateStepNoAllariaAccount,
  onUpdateLoading,
} from "../../../../reducers/accountOpeningForm";
import TokenCard from "../../../ui/TokenCard/TokenCard";
import {
  authEmail,
  authToken,
  getDocumentData,
  getFiscalData,
  getUserActivities,
  getUserProfile,
  updateUserProfile,
} from "../../../../connectors/connectors";
import { useState } from "react";
import { minutesToMiliseconds, genericError } from "../../../utils/utils";
import { getUserAddress } from "../../../../connectors/connectors";
import {
  AllariaUserData,
  AllariaJuridicalUserData,
} from "../../../../reducers/interfaces/types";

const StepTwo = () => {
  const {
    typeOfOpening,
    formWithoutAllariaAccount,
    formWithAllariaAccount,
    formWithAllariaAccountJuridical,
    isJuridical,
  } = useAppSelector((state) => state.accountOpeningForm);
  const dispatch = useAppDispatch();
  const [lastSendToken, setLastSendToken] = useState(Date.now());

  const email =
    typeOfOpening === "WITH_ALLARIA_ACCOUNT"
      ? isJuridical
        ? formWithAllariaAccountJuridical[1].email
        : formWithAllariaAccount[1].personalMail
      : formWithoutAllariaAccount[1].email;

  const onAuthorize = async (token: string) => {
    try {
      const responseToken = await authToken(email, token);

      if (typeOfOpening === "WITH_ALLARIA_ACCOUNT") {
        let allariaUserData!: AllariaUserData;
        let juridicalAllariaUserData!: AllariaJuridicalUserData;

        //Update user profile data
        if (isJuridical) {
          const {
            allariaFileSharing,
            termAndConditions,
            userReferred,
            emailOrNameReferred,
            allariaEmail,
            businessName,
            businessCuit,
            representativeName,
            representativeLastName,
            representativeDni,
            representativeTel,
            hasJointSignature,
            companyType,
            publicEntity,
          } = formWithAllariaAccountJuridical[1];

          juridicalAllariaUserData = {
            screen_spot: 2,
            accepted_sharing_allaria_documents_with_daruma: allariaFileSharing,
            accepted_term_conditions_allaria: termAndConditions,
            was_referred: userReferred,
            chosen_referral: emailOrNameReferred,
            has_allaria_account: true,
            cuit_cuil_dni_allaria: representativeDni,
            name_allaria: representativeName,
            last_name_allaria: representativeLastName,
            allaria_email: allariaEmail,
            person_type: "JURIDICAL",
            allaria_juridical_business_name: businessName,
            allaria_juridical_business_cuit: businessCuit,
            allaria_juridical_tel: representativeTel,
            allaria_juridical_joint_signature: hasJointSignature,
            allaria_juridical_company_type: companyType,
            allaria_juridical_public_entity: publicEntity,
          };
        } else {
          const {
            allariaFileSharing,
            termAndConditions,
            userReferred,
            emailOrNameReferred,
            numberId,
            firstName,
            lastName,
            allariaMail,
          } = formWithAllariaAccount[1];

          allariaUserData = {
            screen_spot: 2,
            accepted_sharing_allaria_documents_with_daruma: allariaFileSharing,
            accepted_term_conditions_allaria: termAndConditions,
            was_referred: userReferred,
            chosen_referral: emailOrNameReferred,
            has_allaria_account: true,
            cuit_cuil_dni_allaria: numberId,
            name_allaria: firstName,
            last_name_allaria: lastName,
            allaria_email: allariaMail,
          };
        }

        await updateUserProfile(
          responseToken.data.token,
          isJuridical ? juridicalAllariaUserData : allariaUserData
        );

        return {
          success: true,
          token: "",
        };
      } else {
        return {
          success: true,
          token: responseToken.data.token,
        };
      }
    } catch (err: any) {
      const errorTag = err.response.data;
      if (
        errorTag.token &&
        (errorTag.token[0] === "The token you entered isn't valid." ||
          errorTag.token[0] === "Invalid Token")
      ) {
        return {
          success: false,
          message: "El token es incorrecto.",
          token: "",
        };
      } else {
        genericError();
        return {
          success: false,
          message: "",
          token: "",
        };
      }
    }
  };

  const onResend = async () => {
    try {
      setLastSendToken(Date.now() + minutesToMiliseconds(2));
      await authEmail(email);
    } catch (err: any) {
      genericError();
    }
  };

  const onSuccess = async (newToken: string) => {
    dispatch(onUpdateLoading(true));
    dispatch(onUpdateToken(newToken));
    if (typeOfOpening === "WITHOUT_ALLARIA_ACCOUNT") {
      await getUserProfile(newToken)
        .then(async (res) => {
          const currentStep = res.data.results[0].screen_spot;
          let isStep3B: boolean = false;

          let dataFourStep = { ...formWithoutAllariaAccount[4] };
          const {
            sex,
            dependency_relationship,
            marital_status,
            telephone,
            pep,
            document_procedure_id,
            document_front_photo,
            document_back_photo,
            was_referred,
            chosen_referral,
            cuil_no_renaper,
            nif,
            accepted_term_conditions,
          } = res.data.results[0];

          if (!formWithoutAllariaAccount[1].userReferred && was_referred) {
            const data = {
              ...formWithoutAllariaAccount[1],
              userReferred: true,
              emailOrNameReferred: chosen_referral,
            };
            dispatch(onUpdateStepNoAllariaAccount({ step: 1, data }));
          }

          await getDocumentData(newToken)
            .then((res) => {
              if (res.data.results.length > 0) {
                const {
                  number_id,
                  names,
                  last_names,
                  birthdate,
                  nationality,
                  cuil,
                  street_address,
                  street_number,
                  floor,
                  department,
                  postal_code,
                  municipality,
                  province,
                  country,
                } = res.data.results[0];

                isStep3B = !!(
                  number_id &&
                  document_procedure_id &&
                  sex &&
                  marital_status &&
                  document_front_photo &&
                  document_back_photo
                );
                const data = {
                  ...formWithoutAllariaAccount[3],
                  currentStepProgress: isStep3B ? 2 : 1,
                  gender: sex,
                  maritalStatus: marital_status,
                  politicallyExposedPerson: pep,
                  telephoneNumber: telephone,
                  DNI: {
                    number: number_id,
                    processNumber: document_procedure_id,
                  },
                  firstName: names,
                  lastName: last_names,
                  dateOfBirth: birthdate,
                  nationality,
                  cuit: cuil ? cuil : cuil_no_renaper ? cuil_no_renaper : "",
                  address: {
                    street: street_address,
                    streetNumber: street_number,
                    floorNumber: floor ? floor : "",
                    department,
                    zipCode: postal_code ? postal_code : "",
                    locality: municipality,
                    province: province,
                    country,
                  },
                  frontDNI: document_front_photo,
                  backDNI: document_back_photo,
                };
                if (postal_code) {
                  dispatch(onUpdateStepNoAllariaAccount({ step: 3, data }));
                } else {
                  getUserAddress(newToken).then((res) => {
                    const zipCode = res.data.results[0].postal_code;
                    dispatch(
                      onUpdateStepNoAllariaAccount({
                        step: 3,
                        data: {
                          ...data,
                          address: { ...data.address, zipCode },
                        },
                      })
                    );
                  });
                }
                dataFourStep = {
                  ...dataFourStep,
                  dependencyRelationship: dependency_relationship,
                };
              }
            })
            .catch(() => genericError());

          if (currentStep === 5) {
            if (accepted_term_conditions) {
              dispatch(onUpdateCompletedForm(true));
            } else {
              await getFiscalData(newToken)
                .then(async (res) => {
                  const fiscalData = res.data.results[0];
                  const taxedOutsideTheCountry: boolean =
                    fiscalData.country_code !== 1 ? true : false;

                  await getUserActivities(newToken).then(async (res) => {
                    const activity = res.data.results[0].activity;
                    dataFourStep = {
                      ...dataFourStep,
                      taxedOutsideTheCountry: taxedOutsideTheCountry
                        ? "YES"
                        : "NO",
                      countryOfTaxation: fiscalData.country_code.toString(),
                      activityCategory: activity.toString(),
                      NIF: fiscalData.fiscal_id,
                    };
                    dispatch(
                      onUpdateStepNoAllariaAccount({
                        step: 4,
                        data: dataFourStep,
                      })
                    );
                  });
                })
                .catch(() => {
                  dispatch(onUpdateLoading(false));
                  genericError();
                });
            }
          }

          if (currentStep < 4) dispatch(onUpdateCurrentStep(3));
          else dispatch(onUpdateCurrentStep(currentStep));
        })
        .catch(() => genericError());
    } else dispatch(onUpdateCompletedForm(true));

    dispatch(onUpdateLoading(false));
  };

  return (
    <>
      <TokenCard
        title="Validá tu email"
        lastSendToken={lastSendToken}
        onAuthorize={onAuthorize}
        onResend={onResend}
        onSuccess={onSuccess}
        description="Te enviamos un mensaje de confirmación de email con un Token para continuar con el proceso de apertura de cuenta. Ingresá a tu email, copiá el código e ingresalo."
        autoSendToken
      />
    </>
  );
};

export default StepTwo;
