import { Col, Row } from "react-bootstrap";
import InputTextNumber from "../../../ui/Inputs/InputTextNumber";
import styles from "../Steps.module.scss";
import { openModal } from "../../../../reducers/modalReducer";
import InputTel from "../../../ui/Inputs/InputTel";
import { ReactComponent as InfoIcon } from "../../../../assets/img/Inputs/infoLightBlueIcon.svg";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  onUpdateCurrentStep,
  onUpdateStepNoAllariaAccount,
} from "../../../../reducers/accountOpeningForm";
import InputSelect from "../../../ui/Inputs/InputSelect";
import InputCuit from "../../../ui/Inputs/InputCuit";
import Autocomplete from "../../../ui/Inputs/Autocomplete";
import { stepThreePart3Schema } from "../../../../schemas/steps";
import FormikWrapper from "../../../ui/FormikWrapper/FormikWrapper";
import StepButtons from "../../../ui/StepButtons/StepButtons";
import {
  saveUserAddress,
  updateUserProfile,
  getCountries,
} from "../../../../connectors/connectors";
import { useEffect, useRef, useState } from "react";
import { genericError } from "../../../utils/utils";

interface StepThreePart2Values {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  nationality: string;
  cuit: string;
  telephoneNumber: string;
  politicallyExposedPerson: string;
  street: string;
  streetNumber: string;
  floorNumber: string;
  department: string;
  zipCode: string;
  locality: string;
  province: string;
}

const StepThreePart3 = () => {
  const { accountOpeningForm, windowWidth } = useAppSelector((state) => state);
  const { formWithoutAllariaAccount, token } = accountOpeningForm;
  const isMobile = windowWidth.width < 768;
  const formikForm = useRef<any>(null);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const {
    firstName,
    lastName,
    dateOfBirth,
    nationality,
    cuit,
    telephoneNumber,
    politicallyExposedPerson,
    address,
  } = formWithoutAllariaAccount[3];

  const initialValues = {
    firstName,
    lastName,
    dateOfBirth,
    nationality,
    cuit: cuit === "0" ? "" : cuit,
    telephoneNumber,
    politicallyExposedPerson,
    ...address,
  };

  const onSubmit = async (values: StepThreePart2Values) => {
    setLoadingButton(true);
    const {
      politicallyExposedPerson,
      street,
      streetNumber,
      floorNumber,
      department,
      zipCode,
      locality,
      province,
      ...rest
    } = values;

    const data = {
      ...formWithoutAllariaAccount[3],
      ...rest,
      politicallyExposedPerson,
      cuit: !formWithoutAllariaAccount[3].cuit ? values.cuit : cuit,
      address: {
        ...formWithoutAllariaAccount[3].address,
        street,
        streetNumber,
        floorNumber,
        department,
        zipCode,
        locality,
        province,
      },
    };
    try {
      const { telephoneNumber, politicallyExposedPerson } =
        formikForm.current.values;
      let userProfile: {
        screen_spot: any;
        telephone: any;
        pep: any;
        cuil_no_renaper?: any;
      } = {
        screen_spot: 4,
        telephone: telephoneNumber,
        pep: politicallyExposedPerson,
      };

      if (!formWithoutAllariaAccount[3].cuit) {
        userProfile.cuil_no_renaper = values.cuit;
      }

      const countriesList = await getCountries(token);
      const countryData = countriesList.data.results.find(
        (ct: any) => ct.name.toLowerCase() === address.country.toLowerCase()
      );
      const info = {
        street_address: street,
        street_number: streetNumber,
        floor: floorNumber,
        apartment: department,
        postal_code: zipCode,
        city: locality,
        province: province,
        //Later, we will have to add a field to municipality and country
        municipality: "",
        country_code: countryData.code,
      };

      await updateUserProfile(token, userProfile);
      await saveUserAddress(info, token);
      setLoadingButton(false);
      dispatch(onUpdateStepNoAllariaAccount({ step: 3, data }));
      dispatch(onUpdateCurrentStep(4));
    } catch (error) {
      genericError();
      setLoadingButton(false);
    }
  };
  useEffect(() => {
    formikForm.current.setFieldValue(
      "politicallyExposedPerson",
      politicallyExposedPerson
    );
    const fieldsTouchedToTrue = [
      "locality",
      "province",
      "politicallyExposedPerson",
      "telephoneNumber",
    ];
    const fieldsTouchedToFalse = ["nationality", "cuil", "zipCode", "street"];
    fieldsTouchedToTrue.forEach((field) =>
      formikForm.current.values[field] !== ""
        ? formikForm.current.setFieldTouched(field, true)
        : null
    );
    fieldsTouchedToFalse.forEach((field) =>
      formikForm.current.values[field] === ""
        ? formikForm.current.setFieldTouched(field, false)
        : null
    );
  }, []);

  return (
    <div className={styles.stepThree}>
      <FormikWrapper
        initialValues={initialValues}
        onSubmit={(values) => {
          onSubmit(values);
        }}
        validationSchema={stepThreePart3Schema}
        innerRef={formikForm}
      >
        {({
          errors,
          handleSubmit,
          values,
          touched,
          getFieldMeta,
          setFieldValue,
        }) => (
          <form>
            <Row>
              <Col md>
                <InputTextNumber
                  label="Nombre/s"
                  name="firstName"
                  placeholder="Nombre"
                  type="text"
                  disabled={!!initialValues.firstName}
                  validateOnChange={!!initialValues.firstName}
                />
              </Col>
              <Col md>
                <InputTextNumber
                  label="Apellido/s"
                  name="lastName"
                  placeholder="Apellido"
                  type="text"
                  disabled={!!initialValues.lastName}
                  validateOnChange={!!initialValues.lastName}
                />
              </Col>
            </Row>
            <Row className="mt-md-3">
              <Col md>
                <InputTextNumber
                  type="text"
                  name="dateOfBirth"
                  label="Fecha de nacimiento"
                  placeholder="XX/XX/XXXX"
                  disabled={!!initialValues.dateOfBirth}
                  validateOnChange={!!initialValues.dateOfBirth}
                />
              </Col>
              <Col md>
                <InputTextNumber
                  type="text"
                  name="nationality"
                  label="Nacionalidad"
                  placeholder="Argentino"
                  disabled={!!initialValues.nationality}
                  validateOnChange={!!initialValues.nationality}
                />
              </Col>
            </Row>
            <Row className="mt-md-3">
              <Col md>
                <InputCuit
                  name="cuit"
                  label="CUIL / CUIT"
                  placeholder="XX-XXXXXXXX-X"
                  disabled={!!initialValues.cuit && initialValues.cuit !== "0"}
                />
              </Col>
              <Col lg>
                <InputTel name="telephoneNumber" label="Teléfono" />
              </Col>
            </Row>
            <Row className="mt-md-3"></Row>
            <Row className="mt-md-3">
              <Col lg>
                <InputTextNumber
                  type="text"
                  name="street"
                  label="Domicilio"
                  placeholder="Calle"
                />
              </Col>
              <Col md>
                <Row>
                  <Col>
                    <InputTextNumber
                      type="number"
                      name="streetNumber"
                      label="Número"
                      placeholder="Número"
                    />
                  </Col>
                  <Col>
                    <InputTextNumber
                      type="text"
                      name="floorNumber"
                      label="Piso"
                      placeholder="1"
                      maxLength={2}
                    />
                  </Col>
                  <Col>
                    <InputTextNumber
                      type="text"
                      name="department"
                      label="Depto"
                      placeholder="1"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-md-3">
              <Col md>
                <InputTextNumber
                  type="text"
                  name="zipCode"
                  label="Código postal"
                  placeholder="7600"
                />
                <div
                  className={
                    errors.zipCode && touched.zipCode
                      ? styles.zipCodeInfoErr
                      : styles.zipCodeInfo
                  }
                >
                  <InfoIcon />
                  <span>
                    ¿No sabés cuál es tu CP?
                    {/* <br/> */}
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.correoargentino.com.ar/formularios/cpa"
                    >
                      <b>¡Buscá el tuyo acá! </b>
                    </a>
                  </span>
                </div>
              </Col>

              <Col md>
                <Autocomplete
                  name="province"
                  label="Provincia"
                  type="province"
                  getFieldMeta={getFieldMeta}
                  setFieldValue={setFieldValue}
                />
              </Col>
            </Row>
            <Row className="mt-md-3">
              <Col md>
                <Autocomplete
                  name="locality"
                  label="Localidad"
                  type="locality"
                  getFieldMeta={getFieldMeta}
                  setFieldValue={setFieldValue}
                />
              </Col>
              <Col md>
                {isMobile && (
                  <label className={styles.labelLink}>
                    PEP{" "}
                    <span
                      className={styles.link}
                      onClick={() => dispatch(openModal({ name: "modalPEP" }))}
                    >
                      ¿Qué es ser PEP?
                    </span>
                  </label>
                )}
                <InputSelect
                  label={!isMobile ? "PEP" : ""}
                  name="politicallyExposedPerson"
                  placeholder="Seleccioná una opción"
                  options={[
                    { value: "NO", label: "No" },
                    { value: "YES_DIRECTLY", label: "Sí, directamente" },
                    { value: "YES_FAMILY", label: "Sí, por vínculo familiar" },
                  ]}
                  tooltip={
                    !isMobile
                      ? {
                          position: "right",
                          text: "¿Qué es ser PEP?",
                          clickHandler: () =>
                            dispatch(openModal({ name: "modalPEP" })),
                        }
                      : undefined
                  }
                />
              </Col>
            </Row>
            <Row className={styles.buttonsRow}>
              <StepButtons
                type="button"
                text="Guardar y Continuar"
                disabled={Object.keys(errors).length > 0 || loadingButton}
                onClick={() => handleSubmit()}
                isLoading={loadingButton}
              />
            </Row>
          </form>
        )}
      </FormikWrapper>
    </div>
  );
};

export default StepThreePart3;
