import {
  ChoicesStepFive,
  Question,
} from "../../../../../reducers/interfaces/types";

export const getTooltipText = (i: number) => {
  const texts = {
    0: "Comprende: Compra/Venta de: Títulos Públicos (Nacionales; Provinciales y Municipales), Letras del BCRA, Fideicomisos Financieros, Cheques de Pago Diferido, Fondos Comunes de Inversión de Renta Fija, Obligaciones Negociables; Caución Colocadora y Préstamo Colocador.",
    1: "Comprende todas las Operaciones de RIESGO BAJO más: Compra/Venta de: acciones ordinarias y preferidas, Fondos Comunes de Inversión de renta variable, Acciones negociadas en mercados del exterior y Préstamo tomador.",
    2: "Comprende todas las Operaciones de RIESGO MEDIO más: Compra/Venta de: futuros (de títulos públicos , de moneda, de títulos privados, de metales o productos agropecuarios), Opciones de títulos privados y públicos; Caución tomadora; compra de activos con apalancamiento sobre garantía de dicho activo y/u otros; préstamo tomador con venta en corto y Fondos Comunes de Inversión Cerrados.",
    3: "",
  };

  return texts[i as keyof typeof texts];
};

export const getFormatData = (values: any) => {
  const formatData = [
    {
      choice: values.capitalMarketKnowledge,
    },
    {
      choice: values.investmentObjective,
    },
    {
      choice: values.investmentTerm,
    },
    {
      choice: values.percentageOfSavingsToBeInvested,
    },
    {
      choice: values.investmentRisk,
    },
    {
      choice: values.sourceOfFunds,
    },
    {
      choice: values.monthlyIncome,
    },
    {
      choice: values.totalEquity,
    },
  ];

  return formatData;
};

export const getOptions = (
  choices: ChoicesStepFive[],
  questionIndex: number
) => {
  if (questionIndex !== 5)
    return choices
      .filter((ch: ChoicesStepFive) => ch.question === questionIndex)
      .map((ch: ChoicesStepFive) => {
        return { value: ch.id, label: ch.choice_text };
      });
  else
    return choices
      .filter((ch: ChoicesStepFive) => ch.question === 5)
      .map((ch: ChoicesStepFive, i: number) => {
        return {
          value: ch.id,
          label: ch.choice_text,
          optionTooltipText: getTooltipText(i),
        };
      });
};

export const getQuestion = (questions: Question[], questionIndex: number) => {
  return questions.find((q: Question) => q.id === questionIndex);
};
