import React, { useEffect, useState } from "react";
import Button from "../Button/Button";
import InputToken from "../InputToken/InputToken";
import styles from "./tokenCard.module.scss";
import { ReactComponent as ErrorIcon } from "../../../assets/img/errorIconFill.svg";
import Countdown from "react-countdown";
import ModalBodyWrapper from "../Modal/ModalBodyWrapper";
import StepButtons from "../StepButtons/StepButtons";
import { createBrowserHistory } from "history";

interface TokenCardProps {
   title: string;
   description: string;
   lastSendToken: number;
   onAuthorize: (token: string) => Promise<{success: boolean; message?: string; token: string;}>;
   onResend: () => void;
   onSuccess: (newToken: string) => void;
   autoSendToken?: boolean;
   disablePrimaryButton?: boolean;
}

const ErrorMessage = ( { message } : {message: string} ) => {
  return (
    <div className={styles.errorMessage}>
      <ErrorIcon/>
      <span>
        {message}
      </span>
    </div>
  );
};

const TokenCard = ( { title, description, onAuthorize, onResend, onSuccess, lastSendToken, autoSendToken = false, disablePrimaryButton } : TokenCardProps ) => {

  const queryString = window.location.search;
  const history = createBrowserHistory()
  const urlToken: string | null = new URLSearchParams(queryString).get("token");
  const [ token, setToken ] = useState(urlToken || "");
  const [ status, setStatus ] = useState({
    error: true,
    message: "",
    loading: false
  });

  useEffect(() => {
    if(token) {
      validateToken(token);
    }    
    return ()=>{
      history.replace('/')
    }
  }, []);

  const handleBlur = () => {
    if (token.length < 6) {
      setStatus({ error: true, message: "Deben ser 6 caracteres.", loading: false });
    }
  };

  const validateToken = (value: string) => {
    const isValid = value.toLocaleUpperCase().match(/^([A-Z0-9])*$/);
    const isValidLength = value.length === 6;
    setStatus({
      ...status,
      error: !isValid || !isValidLength,
      message: !isValid ? "El token es incorrecto." : "",
    });
  };

  useEffect(() => {
    validateToken(token);
  }, [ token]);

  const authorizeToken = async () => {
  
    setStatus({
      ...status,
      loading: true
    });

    const { success, message, token: newToken } = await onAuthorize(token);
    
    if (success) {
      setToken("");
      setStatus({
        ...status,
        loading: false,
        error: false,
      });
      onSuccess(newToken);

    } else {
 
      setStatus({
        ...status,
        loading: false,
        error: true,
        message: message || ""
      });

    } 
  };

  const handleSubmit = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    authorizeToken();
  };

  useEffect(() => {
    if(!status.error && !status.loading && token && autoSendToken) {
      authorizeToken();
    }
  }, [ status ]);

  return (
    <ModalBodyWrapper maxWidth={638} className={styles.bodyWrapper}>
        <div>
          <h3>{title}</h3>
          <p className={styles.description}>{description}</p>
          <div className={styles.inputTokenGroup}>
            <div className={styles.inputToken}>
              <InputToken
                charactersAmount={6}
                token={urlToken}
                setToken={setToken}
                handleOnBlur={() => handleBlur()}
              />
              {status.error && status.message &&
            <ErrorMessage message={status.message}/>
              }
            </div>
              <Countdown
                date={lastSendToken}
                key={lastSendToken}
                renderer={({ minutes, seconds, completed }) => {
                  return (
                    <div className={styles.buttonContainer}>
                      <Button
                        buttonType="tertiary"
                        type="button"
                        text={
                          completed
                            ? "Reenviar token"
                            : `Reenviar token ${minutes}:${
                              seconds < 10 ? `0${seconds}` : seconds
                            }`
                        }
                        disabled={!completed || status.loading}
                        click={onResend}
                        className={`px-0 ${styles.button}`}
                      />
                    </div>
                  );
                }}
              />
          </div>
          <StepButtons
             type="submit"
             text="Siguiente"
             disabled={ token.length < 6 || (status.error && !!status.message )|| status.loading || !!disablePrimaryButton }
             onClick={ handleSubmit }
             isLoading={ status.loading }
          />
        </div>
    </ModalBodyWrapper>  
  );
};
export default TokenCard;