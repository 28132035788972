import { Col, Container, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { closeModal } from "../../../reducers/modalReducer";
import Button from "../Button/Button";
import Modal from "../Modal/Modal";
import ModalBodyWrapper from "../Modal/ModalBodyWrapper";
import styles from "./AllariaNote.module.scss";

const info = {
  title: "Nota a Allaria para compartir legajo con Allaria+ e Instrucción",
  description: (
    <>
      Sres. Allaria.
      <br />
      <br />
      A través de la presente, solicito expresamente tengan a bien compartir a
      Daruma Pagos S.A. CUIT: 30-71615568-0 mi legajo de cliente obrante en
      vuestra entidad, así como la información y documentación en él contenido.
      El motivo de esta solicitud radica en simplificar la gestión de la
      apertura, evitar la duplicación en la información aportada y optimizar la
      tarea de cumplimiento, atento ser cliente de ambas compañías y al poder
      Uds dar fé de mi identidad y facultades al ser sujetos obligados UIF.
      <br />
      <br />
      Asimismo, informo por este medio que aquellas instrucciones de Rescate o
      Suscripción en FCI T+0 que reciban de parte de Allaria+, para mi cuenta
      comitente, son por mi cuenta y orden, y en virtud del mandato permanente
      que otorgué a ellos para colocar aquellos fondos disponibles de mi cuenta
      de Daruma Pagos S.A.
      <br />
      <br />
      Desde ya muchas gracias.
      <br />
      Saluda Atte
    </>
  ),
};

const AllariaNote = () => {
  const { noteContainer, title, description } = styles;
  const dispatch = useAppDispatch();
  const { modalName } = useAppSelector((state: RootState) => state.modal);

  const handleClick = () => dispatch(closeModal());
  return (
    <>
      {modalName === "modal-note" && (
        <Modal onClose={handleClick}>
          <ModalBodyWrapper maxWidth={800}>
            <Container className={noteContainer}>
              <p className={title}>{info.title}</p>
              <p className={description}>{info.description}</p>
              <Row className="w-100 d-flex justify-content-end">
                <Col lg={4}>
                  <a
                    className="text-decoration-none"
                    target="_blank"
                    href={`${process.env.REACT_APP_STATICURL}/templates/2._Nota_Allaria_con_MM_-_para_Online.pdf`}
                    rel="noreferrer"
                  >
                    <Button
                      buttonType="secondary"
                      text="Descargar"
                      type="button"
                      className="w-100"
                    />
                  </a>
                </Col>
                <Col lg={4}>
                  <Button
                    buttonType="primary"
                    text="Salir"
                    type="button"
                    click={handleClick}
                    className="w-100"
                  />
                </Col>
              </Row>
            </Container>
          </ModalBodyWrapper>
        </Modal>
      )}
    </>
  );
};

export default AllariaNote;
