import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AccountOpeningForm,
  StepFiveNoAllariaAccount,
  StepFourNoAllariaAccount,
  StepOneAllariaAccount,
  StepOneNoAllariaAccount,
  StepThreeNoAllariaAccount,
  StepTwoNoAllariaAccount,
  StepOneJuridicalAllariaAccount,
  TypeOfOpening,
} from "./interfaces/types";

const initialState: AccountOpeningForm = {
  typeOfOpening: "WITHOUT_ALLARIA_ACCOUNT",
  token: "",
  currentStep: 1,
  completedForm: false,
  animationDirection: 1,
  formWithoutAllariaAccount: {
    1: {
      email: "",
      userReferred: false,
      emailOrNameReferred: "",
    },
    2: {
      validatedEmail: false,
    },
    3: {
      currentStepProgress: 1,
      DNI: {
        number: null,
        processNumber: null,
      },
      gender: "",
      maritalStatus: "",
      frontDNI: null,
      backDNI: null,
      firstName: "",
      lastName: "",
      dateOfBirth: "",
      nationality: "",
      cuit: "",
      telephoneNumber: "",
      politicallyExposedPerson: "",
      address: {
        street: "",
        streetNumber: "",
        floorNumber: "",
        department: "",
        zipCode: "",
        locality: "",
        province: "",
        country: "",
      },
    },
    4: {
      dependencyRelationship: "",
      activityCategory: "",
      taxedOutsideTheCountry: "",
      countryOfTaxation: "",
      NIF: "",
      hasOpenBankAccount: "",
      CBU: "",
    },
    5: {
      capitalMarketKnowledge: "",
      investmentObjective: "",
      investmentTerm: "",
      percentageOfSavingsToBeInvested: "",
      investmentRisk: "",
      sourceOfFunds: "",
      monthlyIncome: "",
      totalEquity: "",
      descriptionOfSourceFunds: "",
      termAndConditions: false,
    },
  },
  formWithAllariaAccount: {
    1: {
      personalMail: "",
      allariaMail: "",
      firstName: "",
      lastName: "",
      numberId: "",
      userReferred: false,
      termAndConditions: false,
      allariaFileSharing: false,
      emailOrNameReferred: "",
    },
  },
  formWithAllariaAccountJuridical: {
    1: {
      email: "",
      allariaEmail: "",
      businessName: "",
      businessCuit: "",
      representativeName: "",
      representativeLastName: "",
      representativeDni: "",
      representativeTel: "",
      userReferred: false,
      emailOrNameReferred: "",
      termAndConditions: false,
      allariaFileSharing: false,
      hasJointSignature: false,
      companyType: "",
      publicEntity: false,
    },
  },
  isLoading: false,
  isJuridical: false,
};

const isStepThree = (object: any): object is StepThreeNoAllariaAccount => {
  if (object) {
    return "currentStepProgress" in object;
  }
  return false;
};

const accountOpeningForm = createSlice({
  name: "accountOpeningForm",
  initialState,
  reducers: {
    onUpdateStepNoAllariaAccount: (
      state,
      action: PayloadAction<{
        step: number;
        data:
          | StepOneNoAllariaAccount
          | StepTwoNoAllariaAccount
          | StepThreeNoAllariaAccount
          | StepFourNoAllariaAccount
          | StepFiveNoAllariaAccount;
      }>
    ) => {
      const { data } = action.payload;
      let animationDirection: 1 | -1 = 1;
      if (isStepThree(data)) {
        animationDirection =
          data.currentStepProgress >
          state.formWithoutAllariaAccount[3].currentStepProgress
            ? 1
            : -1;
      }

      return {
        ...state,
        animationDirection,
        formWithoutAllariaAccount: {
          ...state.formWithoutAllariaAccount,
          [action.payload.step]: action.payload.data,
        },
      };
    },
    onUpdateStepAllariaAccount: (
      state,
      action: PayloadAction<{ step: number; data: StepOneAllariaAccount }>
    ) => {
      return {
        ...state,
        formWithAllariaAccount: {
          ...state.formWithAllariaAccount,
          [action.payload.step]: action.payload.data,
        },
      };
    },
    onUpdateStepAllariaAccountJuridical: (
      state,
      action: PayloadAction<{
        step: number;
        data: StepOneJuridicalAllariaAccount;
      }>
    ) => {
      return {
        ...state,
        formWithAllariaAccountJuridical: {
          ...state.formWithAllariaAccountJuridical,
          [action.payload.step]: action.payload.data,
        },
      };
    },
    onUpdateTypeOfOpening: (state, action: PayloadAction<TypeOfOpening>) => {
      return { ...state, typeOfOpening: action.payload };
    },
    onUpdateToken: (state, action: PayloadAction<string>) => {
      return { ...state, token: action.payload };
    },
    onUpdateCurrentStep: (state, action: PayloadAction<number>) => {
      const animationDirection = action.payload > state.currentStep ? 1 : -1;
      return { ...state, currentStep: action.payload, animationDirection };
    },
    onUpdateCompletedForm: (state, action: PayloadAction<boolean>) => {
      return { ...state, completedForm: action.payload };
    },
    onResetForm: () => {
      return initialState;
    },
    onUpdateAnimationDirection: (state, action: PayloadAction<1 | -1>) => {
      return { ...state, animationDirection: action.payload };
    },
    onUpdateLoading: (state, action: PayloadAction<boolean>) => {
      return { ...state, isLoading: action.payload };
    },
    onUpdateJuridical: (state, action: PayloadAction<boolean>) => {
      return { ...state, isJuridical: action.payload };
    },
  },
});

export const {
  onUpdateStepNoAllariaAccount,
  onUpdateStepAllariaAccount,
  onUpdateTypeOfOpening,
  onUpdateToken,
  onUpdateCurrentStep,
  onUpdateCompletedForm,
  onResetForm,
  onUpdateAnimationDirection,
  onUpdateLoading,
  onUpdateJuridical,
  onUpdateStepAllariaAccountJuridical,
} = accountOpeningForm.actions;

export default accountOpeningForm.reducer;
