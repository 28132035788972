import Form from "react-bootstrap/Form";
import Select, { CSSObjectWithLabel, SingleValue } from "react-select";
import { useField } from "formik";
import styles from "./Inputs.module.scss";
import { CustomOption, InputError } from "./UtilsInputs";
import Tooltip from "../Tooltip/Tooltip";
import infoBlueIcon from "../../../assets/img/Inputs/infoBlueIcon.svg";
import { Placement } from "react-bootstrap/esm/types";
import { MouseEventHandler, useState, useEffect, ReactNode } from "react";
import { Option } from "../../../reducers/interfaces/types";

interface InputSelectProps {
  label?: string;
  options: Option[];
  name: string;
  placeholder?: string;
  tooltip?: {
    text: string;
    position: Placement | undefined;
    clickHandler?: MouseEventHandler;
  };
  firstLetterHeader?: boolean;
  disabled?: boolean;
  onChange?: () => void;
  isSearchable?: boolean;
  noOptionsMessage?: string;
}

const InputSelect = (props: InputSelectProps) => {
  const {
    tooltip,
    label,
    options,
    firstLetterHeader,
    disabled,
    onChange,
    isSearchable,
    placeholder,
    noOptionsMessage,
  } = props;
  const { InputSelect, TooltipIcon } = styles;
  const [field, { error, touched, ...meta }, helpers] = useField(props);
  const [borderClass, setBorderClass] = useState("");
  const [letterHeader, setLetterHeader] = useState("");
  const [selectedValue, setSelectedValue] = useState<Option>();

  let initialValue;
  if (meta.initialValue) {
    const value = meta.initialValue;
    initialValue = {
      value: value,
      label: options.find((option) => option.value == value)?.label,
    };
  } else {
    initialValue = {
      value: "",
      label: "Seleccioná una opción",
    };
  }

  const handleChange = (
    value: SingleValue<{ value: any; label: string | ReactNode | undefined }>
  ) => {
    onChange && onChange();
    helpers.setValue(value);
  };

  useEffect(() => {
    if (error && touched) setBorderClass("is-invalid");
    if (!error && touched) setBorderClass("is-valid");
  }, [error, touched]);

  const defaultStyles = {
    menu: (provided: CSSObjectWithLabel) => ({
      ...provided,
      padding: "0.4rem 0",
      marginTop: "0.5rem",
    }),
    singleValue: (provided: CSSObjectWithLabel, state: any) => ({
      ...provided,
      color: state.children === "Seleccioná una opción" ? "#8690A1" : "#001c40",
      fontSize: "0.875rem",
      fontWeight: state.children === "Seleccioná una opción" ? "500" : "400",
      lineHeight: "35px",
      marginTop: "-3px",
    }),
    control: (provided: CSSObjectWithLabel) => ({
      ...provided,
      height: 35,
      minHeight: 35,
    }),
  };

  const customStyles = {
    ...defaultStyles,
    menu: (provided: CSSObjectWithLabel) => ({
      ...provided,
      padding: "0.4rem 0",
      marginTop: "2.4rem",
    }),
    menuList: (provided: CSSObjectWithLabel) => ({
      ...provided,
      maxHeight: "295px",
    }),
  };

  return (
    <Form.Group className={InputSelect}>
      <Form.Label>
        {label}
        {tooltip && (
          <Tooltip
            text={tooltip.text}
            position={tooltip.position}
            clickHandler={tooltip.clickHandler}
            interactive
          >
            <img
              src={infoBlueIcon}
              alt="icon-tooltip"
              className={TooltipIcon}
            />
          </Tooltip>
        )}
      </Form.Label>
      <Select
        noOptionsMessage={() => noOptionsMessage}
        // @ts-ignore
        styles={firstLetterHeader ? customStyles : defaultStyles}
        classNamePrefix="myselect"
        className={borderClass}
        options={options}
        defaultValue={initialValue}
        value={selectedValue ? selectedValue : initialValue}
        placeholder={placeholder}
        isSearchable={isSearchable || false}
        closeMenuOnSelect
        onChange={handleChange}
        onBlur={() => helpers.setTouched(true)}
        onMenuClose={() => setLetterHeader("")}
        loadingMessage={() => "lala"}
        isDisabled={disabled}
        components={{
          Option: (props) => (
            <CustomOption
              props={props}
              setLetterHeader={setLetterHeader}
              setSelectedValue={setSelectedValue}
            />
          ),
          IndicatorSeparator: null,
        }}
      />
      {firstLetterHeader && letterHeader && (
        <div className={styles.HeaderSelect}>
          <p>{letterHeader}</p>
        </div>
      )}
      <InputError touched={touched} error={error} />
    </Form.Group>
  );
};

export default InputSelect;
