import styles from "./InfoAlert.module.scss";
import infoBlueIcon from "../../../assets/img/Inputs/infoLightBlueIcon.svg";
import infoLightBlueIcon from "../../../assets/img/Inputs/icon-info-light.svg";

interface Props {
  text: string | React.ReactNode;
  variant? : "LIGHT" | "DARK"
  containerClassName?: string
}

const InfoAlert = ({ text, variant = "LIGHT", containerClassName }: Props) => {
  const { container, darkStyle, icon, alertText } = styles;
  return (
    <div className={`${container} ${containerClassName?? ""} ${variant === "DARK"? darkStyle : ""}`}>
      <img src={variant === "LIGHT" ? infoBlueIcon : infoLightBlueIcon} alt="infoIcon" className={icon} />
      <div className={`${alertText}`}>{text}</div>
    </div>
  );
};

export default InfoAlert;
