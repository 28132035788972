import axios from "axios";
import {
  SaveDocumentWithAllaria,
  UserChoices,
  UserProfileStepFive,
  UserProfileWithAllaria,
  SaveUserActivity,
  UserProfileStepFour,
} from "../reducers/interfaces/types";

const DARUMA_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const authEmail = (email: string) => {
  return axios.post(`${DARUMA_BASE_URL}/auth/email/`, { email: email });
};
export const authToken = (email: string, token: string) => {
  return axios.post(`${DARUMA_BASE_URL}/auth/token/`, { email, token });
};

export const getUserProfile = (token: string) => {
  return axios.get(`${DARUMA_BASE_URL}/api/v1/user/profile/`, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
};

export const getSessionId = (token: string) => {
  return axios.get(`${DARUMA_BASE_URL}/api/v1/face-liveness-detection/`, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
};

export const retrieveLivenessResult = (token: string, sessionId: string) => {
  return axios.post(
    `${DARUMA_BASE_URL}/api/v1/face-liveness-detection/`,
    { sessionId: sessionId },
    {
      headers: {
        Authorization: `Token ${token}`,
      },
    }
  );
};

export const validateRenaperData = (
  number: any,
  order: any,
  gender: string,
  token: string
) => {
  return axios.post(
    `${DARUMA_BASE_URL}/api/v1/renaper-data/`,
    {
      number,
      order,
      gender,
    },
    {
      headers: {
        Authorization: `Token ${token} `,
      },
    }
  );
  //       return new Promise((resolve, reject) => {
  //       setTimeout(() => {
  //           resolve({data:
  //             {
  //                 "code": 10001,
  //                 "message": "Exito",
  //                 "person": "{\"number\":\"35971811\",\"gender\":\"M\",\"names\":\"Nazaren\",\"lastNames\":\"COVIAN\",\"birthdate\":\"18/04/1991\",\"copy\":\"B\",\"expirationDate\":\"09/09/2029\",\"creationDate\":\"09/09/2014\",\"cuil\":null,\"streetAddress\":null,\"numberStreet\":null,\"floor\":null,\"department\":\"\",\"zipCode\":null,\"city\":\"VILLA BALLESTER\",\"municipality\":\"GENERAL SAN MARTíN\",\"province\":\"BUENOS AIRES\",\"country\":\"ARGENTINA\",\"messageOfDeath\":\"Sin Aviso de Fallecimiento\",\"nationality\":\"\",\"countryBirth\":\"ARGENTINA\"}",
  //                 "valid": "Vigente"
  //             }
  //          });
  //       }, 1000);
  //   });
};

export const validateRenaperImage = (
  base64Image: string,
  dni: string,
  sex: "M" | "F",
  token: string
) => {
  return axios.post(
    `${DARUMA_BASE_URL}/api/v1/renaper-photo-validation/`,
    {
      image: base64Image,
      dni,
      sex,
    },
    {
      headers: {
        Authorization: `Token ${token} `,
      },
    }
  );

  // // Mock
  // return new Promise((resolve, reject) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         "status": HIT,
  //         "score": 9999
  //     },
  //     });
  //   }, 3000);
  // });
};

export const saveUserAddress = (userAddress: any, token: string) => {
  return axios.post(`${DARUMA_BASE_URL}/api/v1/user-addresses/`, userAddress, {
    headers: { Authorization: `Token ${token}` },
  });
};

export const uploadPhoto = (
  file: any,
  type: "BACK" | "FRONT" | "SELFIE",
  token: string
) => {
  const formdata = new FormData();
  formdata.append("file", file);
  formdata.append("type", type);
  return axios.post(`${DARUMA_BASE_URL}/api/v1/upload/photo/`, formdata, {
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

//Replace any for userProfileWithoutAllaria props, to use same function in both cases
export const updateUserProfile = (
  token: string,
  data: UserProfileWithAllaria | UserProfileStepFive | UserProfileStepFour | any
) => {
  return axios.post(`${DARUMA_BASE_URL}/api/v1/user/profile/`, data, {
    headers: { Authorization: `Token ${token}` },
  });
};

export const saveDocumentData = (
  token: string,
  data: SaveDocumentWithAllaria
) => {
  return axios.post(`${DARUMA_BASE_URL}/api/v1/document-data/`, data, {
    headers: { Authorization: `Token ${token}` },
  });
};

export const getQuestions = (token: string) => {
  return axios.get(`${DARUMA_BASE_URL}/api/v1/questions/`, {
    headers: { Authorization: `Token ${token}` },
  });
};

export const getChoices = (token: string) => {
  return axios.get(`${DARUMA_BASE_URL}/api/v1/choices/`, {
    headers: { Authorization: `Token ${token}` },
  });
};

export const setUserChoices = (token: string, data: UserChoices[]) => {
  return axios.post(`${DARUMA_BASE_URL}/api/v1/user/choices/`, data, {
    headers: { Authorization: `Token ${token}` },
  });
};

//Step 4 without allaria
export const getActivities = (token: string) => {
  return axios.get(`${DARUMA_BASE_URL}/api/v1/activities/`, {
    headers: { Authorization: `Token ${token}` },
  });
};

export const getCountries = (token: string) => {
  return axios.get(`${DARUMA_BASE_URL}/api/v1/countries/`, {
    headers: { Authorization: `Token ${token}` },
  });
};

export const saveUserActivities = (token: string, data: SaveUserActivity) => {
  return axios.post(`${DARUMA_BASE_URL}/api/v1/user-activities/`, data, {
    headers: { Authorization: `Token ${token}` },
  });
};

export const saveFiscalData = (token: string, data: any) => {
  return axios.post(`${DARUMA_BASE_URL}/api/v1/fiscal-data/`, data, {
    headers: { Authorization: `Token ${token}` },
  });
};

export const getFiscalData = (token: string) => {
  return axios.get(`${DARUMA_BASE_URL}/api/v1/fiscal-data`, {
    headers: { Authorization: `Token ${token}` },
  });
};

export const getUserActivities = (token: string) => {
  return axios.get(`${DARUMA_BASE_URL}/api/v1/user-activities`, {
    headers: { Authorization: `Token ${token}` },
  });
};

export const getDocumentData = (token: string) => {
  return axios.get(`${DARUMA_BASE_URL}/api/v1/document-data/`, {
    headers: { Authorization: `Token ${token}` },
  });
};

export const getUserAddress = (token: string) => {
  return axios.get(`${DARUMA_BASE_URL}/api/v1/user-addresses/`, {
    headers: { Authorization: `Token ${token}` },
  });
};
