import styles from "./layout.module.scss";

const Footer = () => {
  const { linkTo, footer } = styles;

  return (
    <div className={footer}>
      <p>
        ¿Ya tenés cuenta en Allaria+?
        <a
          href="https://app.allariamas.com.ar"
          rel="noopener noreferrer"
          target="_blank"
        >
          Iniciá sesión
        </a>
      </p>
      <span className="d-none d-md-block">
        Por cualquier consulta, comunicate con nosotros a través de{" "}
        <a className={linkTo} href="mailto:hola@allariamas.com.ar">
          hola@allariamas.com.ar
        </a>{" "}
        o envianos un mensaje por{" "}
        <a
          className={linkTo}
          href="https://wa.me/5491138499171"
          target="_blank"
          rel="noreferrer"
        >
          Whatsapp al +54 9 113849-9171
        </a>
      </span>
    </div>
  );
};

export default Footer;
