import { RootState } from "../../../app/store";
import { closeModal } from "../../../reducers/modalReducer";
import Button from "../Button/Button";
import Modal from "../Modal/Modal";
import ModalBodyWrapper from "../Modal/ModalBodyWrapper";
import styles from "./PEPModal.module.scss";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import PEPText from "./PEPText";

const PEPModal = () => {
  const {
    modal: { modalName },
    windowWidth,
  } = useAppSelector((state: RootState) => state);
  const dispatch = useAppDispatch();
  const { headerTitle, wrapper, header, modal, footer } = styles;
  const isMobile = windowWidth.width < 768;

  return (
    <>
      {modalName === "modalPEP" && (
        <Modal
          closeIcon={{ hide: isMobile ? true : false }}
          onClose={() => dispatch(closeModal())}
        >
          <ModalBodyWrapper maxWidth={800} className={wrapper}>
            <div className={modal}>
              <div className={header}>
                <h1 className={`${headerTitle} mb-3`}>¿Qué es ser PEP?</h1>
                <p className="mb-1">
                  Resoluciones 134/2018 de la UIF y modificatorias.
                </p>
                <div className="d-flex flex-row justify-content-between">
                  <p className="mb-1 text-gray">RESOL-2023-35-APN-UIF#MEC</p>
                  <p className="mb-1 text-gray">
                    Fecha de publicación 31/03/2023
                  </p>
                </div>
                <hr className="my-3" />
              </div>
              <PEPText />
              {isMobile && (
                <footer className={footer}>
                  <Button
                    type="button"
                    click={() => dispatch(closeModal())}
                    className="mt-3 w-100 py-3"
                    buttonType="primary"
                    text="Volver"
                  />
                </footer>
              )}
            </div>
          </ModalBodyWrapper>
        </Modal>
      )}
    </>
  );
};

export default PEPModal;
