import { MouseEvent } from "react";
import { Col, Row } from "react-bootstrap";
import { useAppDispatch } from "../../../app/hooks";
import { openModal } from "../../../reducers/modalReducer";
import Button from "../Button/Button";

interface Props {
    type: "button" | "submit";
    text: string;
    disabled: boolean;
    isLoading?: boolean;
    onClick?: (event : MouseEvent<HTMLElement>) => void;
}

const StepButtons = ({ text, type, onClick, disabled, isLoading = false }: Props ) => {

    const dispatch = useAppDispatch();

    return (
        <Row className="mt-4 d-flex align-items-center">
            <Col xs={12} md={6}>
                <Button
                    buttonType="primary"
                    width="100%"
                    type={type}
                    text={text}
                    disabled={disabled}
                    click={onClick}
                    isLoading={isLoading}
                />
            </Col>
            <Col xs={12} md={6}>
                <Button
                    buttonType="tertiary"
                    type="button"
                    text="Cancelar apertura"
                    width="100%"
                    click={() => dispatch(openModal({ name:"modal-cancel" })) }
                />
            </Col>
        </Row>
    )
};

export default StepButtons;
