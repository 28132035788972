import Form from 'react-bootstrap/Form';
import { useField } from 'formik';
import styles from './Inputs.module.scss';

interface CheckboxProps {
    name: string;
    boxcolor: "primary" | "lightblue"
    /** For example: span, label, p*/
    label?: React.ReactNode;
    disabled?: boolean;
    width?: string;
}

const InputCheckbox = (props: CheckboxProps) => {
    
    const [field] = useField(props);
    const { FormGroupCheckbox, Checkbox } = styles;

    return (
        <Form.Group controlId={props.name} className={FormGroupCheckbox} style={{ width : props.width || '100%'}}>
            <Form.Check
                {...field}
                {...props}
                className={`${Checkbox} ${styles[props.boxcolor]}`}
                disabled={props.disabled}
            />
        </Form.Group>
    );
};

export default InputCheckbox;
