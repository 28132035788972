import { doc, getFirestore, setDoc } from "firebase/firestore";
import { addMessage } from "../../../utils/addMessage";
import {
  FirebaseUserLivenessValidation,
  FirestoreLivenessData,
} from "../../../../interfaces/types";

export const convertMaritalStatus = (
  status: "single" | "married" | "divorced" | "widowed"
) => {
  if (status === "single") return "SOLTERO";
  if (status === "married") return "CASADO";
  if (status === "divorced") return "DIVORCIADO";
  if (status === "widowed") return "VIUDO";
};

export const convertPep = (
  status: "0" | "1" | "2" | "NO" | "YES_DIRECTLY" | "YES_FAMILY" | "",
  revert: boolean = false
) => {
  if (!revert) {
    if (status === "0") return "NO";
    if (status === "1") return "YES_DIRECTLY";
    if (status === "2") return "YES_FAMILY";
  }
  if (status === "NO") return "0";
  if (status === "YES_DIRECTLY") return "1";
  if (status === "YES_FAMILY") return "2";
  return "";
};

export const renameKeys = (obj: any, old_key: string, new_key: string) => {
  obj[new_key] = obj[old_key];
  delete obj[old_key];
};

export const toastifyError = (description: string) => {
  addMessage({
    message: <p className="ml-2 text-dark mb-0">{description}</p>,
    messageId: "localStorageMessage",
    type: "error",
    showOverPage: true,
    position: "top-right",
  });
};

export const isDataUndefined = (data: FirebaseUserLivenessValidation) => {
  for (const element in data) {
    if (element === undefined || element === null) return true;
  }
  return false;
};

export const saveToFirestoreLiveness = ({
  email,
  isLivenessValid,
  attemptsDesktop,
  attemptsMobile,
  scannedQR,
  token,
}: FirestoreLivenessData) => {
  const db = getFirestore();
  const livenessRef = doc(db, "userLivenessValidation", token);
  setDoc(
    livenessRef,
    { email, isLivenessValid, attemptsDesktop, attemptsMobile, scannedQR },
    { merge: true }
  );
};
