import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useField } from "formik";
import styles from "./Inputs.module.scss";
import Button from "../Button/Button";
import { useAppDispatch } from "../../../app/hooks";
import { onResetFileErrors, onUpdateError } from "../../../reducers/fileErrors";
import { bytesToMegabytes, MAX_FILE_SIZE } from "./UtilsInputs";
import { ReactComponent as PlusCircle } from "../../../assets/img/plus-circle.svg";
import { ReactComponent as SuccessIcon } from "../../../assets/img/new-success.svg";
import { ReactComponent as ErrorIcon } from "../../../assets/img/new-error.svg";
import heic2any from "heic2any";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { UserImageData, ImageValidationData } from "../../../interfaces/types";
import { blobToFile, resizeFile } from "../../utils/utils";

interface InputFileProps {
  name: string;
  externalError?: boolean;
  token: string;
  uploadedFile?: File | null;
}

const heicFileTypes = ["image/heif", "image/heic", ".heic", ".heif"];

const InputFile = (props: InputFileProps) => {
  const dispatch = useAppDispatch();
  const [field, meta, helpers] = useField(props.name);
  const [selectedFile, setSelectedFile] = useState<File | number | undefined>(
    field.value?.name || field.value ? field.value : undefined
  );

  // Get a reference to the storage service, which is used to create references in your storage bucket
  const storage = getStorage();

  // Create a storage reference from our storage service
  const storageRef = ref(storage, `${props.token}/${props.name}`);

  const db = getFirestore();

  const handleClickSelectFile = () => {
    dispatch(onResetFileErrors());
    const input = document.getElementById(props.name) as HTMLInputElement;
    input.click();
  };

  const validFileType = (file: File | undefined | number) => {
    if (typeof file === "number") {
      return typeof file === "number";
    } else
      return (
        file?.type === "image/jpeg" ||
        file?.type === "image/png" ||
        file?.type === "image/jpg" ||
        file?.type === "image/heif" ||
        file?.type === "image/heic" ||
        file?.type === ".heic" ||
        file?.type === ".heif"
      );
  };

  const validFileSize = (file: any) => {
    if (file?.size > MAX_FILE_SIZE) return false;
    return true;
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    let file = event.target?.files?.[0];

    if (!file) {
      helpers.setValue("");
      helpers.setError("Este campo es obligatorio");
      setSelectedFile(undefined);
      return;
    }

    // HEIC TO JPEG CONVERSION
    if (heicFileTypes.includes(file.type)) {
      const newType = "image/jpeg";
      await heic2any({ blob: file, toType: newType })
        .then((conversionResult) => {
          if (!Array.isArray(conversionResult)) {
            const fileFromBlob = blobToFile(
              conversionResult,
              String(file?.name),
              newType
            );

            file = fileFromBlob;
          }
        })
        .catch((err) => {
          helpers.setError("El archivo que cargaste es incorrecto.");
          return err;
        });
    }

    file = await resizeFile(file, 600, file.type);

    event.target.value = "";
    uploadStorageAndFirestore(file);
    dispatch(onResetFileErrors());
    setSelectedFile(file);
    helpers.setTouched(true);
    helpers.setValue(file);
    helpers.setError(undefined);
  };

  useEffect(() => {
    if (selectedFile) {
      if (validFileSize(selectedFile)) {
        if (!validFileType(selectedFile))
          dispatch(
            onUpdateError(
              "El tipo de archivo que cargaste es incorrecto. Asegurate de adjuntar imágenes en formato jpg, jpeg, png o heic."
            )
          );
      } else
        dispatch(
          onUpdateError(
            `El tamaño del archivo no debe exceder los ${bytesToMegabytes(
              MAX_FILE_SIZE
            )}MB.`
          )
        );
    }
  }, [selectedFile]);

  useEffect(() => {
    if (props.uploadedFile) {
      setSelectedFile(props.uploadedFile);
      dispatch(onResetFileErrors());
      helpers.setTouched(true);
      helpers.setValue(props.uploadedFile);
      helpers.setError(undefined);
    } else {
      setSelectedFile(undefined);
    }
  }, [props.uploadedFile]);

  const uploadStorageAndFirestore = (file: File) => {
    if (props.token && file && validFileType(file) && validFileSize(file)) {
      // Upload the file and metadata
      const uploadTask = uploadBytes(storageRef, file);

      uploadTask
        .then((res) => {
          saveToFirestore(res.ref.fullPath, file.name);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  const saveToFirestore = (
    storageFullPath: string,
    originalFileName: string
  ) => {
    const name = props.name;
    const imageRef = doc(db, "userImages", props.token);
    const imageObj: UserImageData & ImageValidationData = {
      uploaded: true,
      storageFullPath,
      originalFileName,
    };

    setDoc(
      imageRef,
      {
        [name]: imageObj,
      },
      { merge: true }
    );
  };

  return (
    <Form.Group controlId={props.name}>
      <div className={styles.inputFile}>
        <input
          type="file"
          id={props.name}
          name={props.name}
          style={{ display: "none" }}
          onChange={(e) => handleChange(e)}
          accept="image/png, image/jpeg, image/png, image/heif, image/heic, .heic, .heif"
        />
        <Button
          buttonType="secondary"
          type="button"
          text={selectedFile ? "Reemplazar imagen" : "Adjuntar imagen"}
          click={handleClickSelectFile}
          icon={selectedFile ? undefined : <PlusCircle />}
          error={
            !!selectedFile &&
            (props.externalError ||
              !validFileType(selectedFile) ||
              !validFileSize(selectedFile))
          }
        />
      </div>
      <div className={styles.attachmentFeedback}>
        {!!selectedFile && (
          <label className="mb-0">
            {validFileType(selectedFile) &&
            validFileSize(selectedFile) &&
            !props.externalError ? (
              <div className={styles.svgContainer}>
                <SuccessIcon />
              </div>
            ) : (
              <div className={styles.svgContainer}>
                <ErrorIcon />
              </div>
            )}
            {typeof selectedFile !== "number" ? <p>{selectedFile.name}</p> : ""}
          </label>
        )}
      </div>
    </Form.Group>
  );
};

export default InputFile;
