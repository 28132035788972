import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import modalReducer from '../reducers/modalReducer';
import accountOpeningForm from '../reducers/accountOpeningForm';
import {combineReducers} from "redux"; 
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import windowWidthReducer from '../reducers/windowWidthReducer';
import localStorageReducer from '../reducers/localStorageReducer';
import fileErrors from '../reducers/fileErrors';

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["accountOpeningForm"]
}

const reducers = combineReducers({
  localStorage: localStorageReducer,
  modal: modalReducer,
  accountOpeningForm,
  windowWidth: persistReducer(persistConfig, windowWidthReducer),
  fileErrors:fileErrors
 });

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    //@redux/toolkit is offering extra protection for correct immutability and serialization of your state.
    //The previous configuration did not have this middleware
    //Solved those error with this lines
    immutableCheck: false,
    serializableCheck: false
  }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
