import { MouseEventHandler } from "react";
import styles from "./stepper.module.scss";
import { motion } from "framer-motion";

interface StepProps {
  title: string;
  subtitle: string;
  currentStep: "active" | "fill" | "disabled";
  handleClick?: MouseEventHandler<HTMLDivElement> | undefined;
}
const Step = ({ title, subtitle, currentStep, handleClick }: StepProps) => {
  const { line, circle, lastStep, text, textActive, textDisabled, prevStep } =
    styles;

  const textStyles = {
    active: textActive,
    disabled: textDisabled,
    fill: text,
  };

  const animationDuration = 0.5;

  return (
    <div
      className={`d-flex mb-1 ${styles[currentStep]} ${lastStep} ${
        handleClick && currentStep === "fill" && prevStep
      }`}
      onClick={handleClick}
    >
      <motion.div
        className="d-flex flex-md-column align-items-center pe-2 pt-1 "
        layout="position"
        transition={{ duration: animationDuration }}
      >
        <motion.div
          layout="position"
          transition={{ duration: 2 }}
          className={`rounded-circle mb-1 ${circle}`}
        ></motion.div>
        <motion.div
          className={`my-2 ${line}`}
          layout="position"
          transition={{ duration: animationDuration }}
        />
      </motion.div>
      <motion.div
        layout="position"
        transition={{ duration: animationDuration }}
        className={`d-none d-md-block ${textStyles[currentStep]}`}
      >
        <p>
          <b>{title}</b>
        </p>
        <p className="pb-4">{subtitle}</p>
      </motion.div>
    </div>
  );
};

export default Step;
