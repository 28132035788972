import { useCallback, useRef, useEffect } from "react";
import Tooltip from "../Tooltip/Tooltip";
import { useInView } from "react-intersection-observer";
import { GroupBase, OptionProps } from "react-select";
import styles from "./Inputs.module.scss";
import infoBlueIcon from "../../../assets/img/Inputs/infoBlueIcon.svg";
import { Option } from "../../../reducers/interfaces/types";
import errorIcon from "../../../assets/img/icon-error.svg";

interface CustomOptionProps {
  props: OptionProps<any, false, GroupBase<any>>;
  setLetterHeader: React.Dispatch<React.SetStateAction<string>>;
  setSelectedValue: React.Dispatch<React.SetStateAction<Option | undefined>>;
}

export const CustomOption = ({
  props,
  setLetterHeader,
  setSelectedValue,
}: CustomOptionProps) => {
  const { TooltipIconOptionSelect, CustomOption, selectedValue } = styles;

  const ref = useRef();
  const { ref: inViewRef, entry } = useInView({
    root: document.querySelector(".myselect__menu-list"),
    threshold: 1,
    rootMargin: "0px 0px -86% ",
  });

  const setRefs = useCallback(
    (node: any) => {
      ref.current = node;
      inViewRef(node);
    },
    [inViewRef]
  );

  const handleClick = () => {
    props.setValue(props.data.value, "select-option");
    setSelectedValue(props.data);
  };

  useEffect(() => {
    if (entry?.isIntersecting)
      setLetterHeader(entry.target.innerHTML.substring(0, 1));
  }, [entry, setLetterHeader]);

  return (
    <div className={`${CustomOption}`} onClick={handleClick}>
      {props.data.optionTooltipText ? (
        <div className="d-flex justify-content-between">
          <p ref={setRefs}>{props.children}</p>
          <Tooltip text={props.data.optionTooltipText} position="right">
            <img
              src={infoBlueIcon}
              alt="icon-tooltip"
              className={TooltipIconOptionSelect}
            />
          </Tooltip>
        </div>
      ) : (
        <p
          ref={setRefs}
          className={
            props.getValue()[0].label === props.data.label ? selectedValue : ""
          }
        >
          {props.children}
        </p>
      )}
    </div>
  );
};

export const InputError = ({
  touched,
  error,
}: {
  touched: boolean;
  error?: string;
}) => {
  const { icon } = styles;

  return (
    <div
      style={{
        visibility:
          touched && !!error && error !== "FORMAT-ERROR" ? "visible" : "hidden",
      }}
      className="d-flex align-items-center"
    >
      <img src={errorIcon} alt="error" className={icon} />
      <p className="invalidField mb-0 mt-1">{error}</p>
    </div>
  );
};
export const bytesToMegabytes = (bytes: number) => {
  return Math.round(bytes / 1024 / 1024);
};
export const MAX_FILE_SIZE = 15728640;
