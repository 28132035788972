import { useEffect } from "react";
import Button from "../ui/Button/Button";
import styles from "./successPage.module.scss";
import iconSuccess from "../../assets/img/success.svg";
import { Container, Row } from "react-bootstrap";
import imgLogoAllariaMas from "../../assets/img/allaria-plus-logo.svg";
import { ReactComponent as AllariaMasMobile } from "../../assets/img/allaria-plus-logo-white.svg";
import { useAppDispatch } from "../../app/hooks";
import { onResetForm } from "../../reducers/accountOpeningForm";
import { motion } from "framer-motion";

interface ButtonCommonProps {
  width: string;
  type: "button" | "submit";
}

interface SuccessPageProps {
  setShowSuccessView: React.Dispatch<React.SetStateAction<boolean>>;
}

const SuccessPage = ({ setShowSuccessView }: SuccessPageProps) => {
  const {
    background,
    successContainer,
    logo,
    description,
    footer,
    button,
    toolbar,
  } = styles;

  const dispatch = useAppDispatch();

  const buttonCommonProps: ButtonCommonProps = {
    width: "16.5rem",
    type: "button",
  };

  useEffect(() => {
    dispatch(onResetForm());
  }, []);

  const handleOpenAccount = () => setShowSuccessView(false);

  const handleClickHome = () =>
    (window.location.href = "https://app.allariamas.com.ar");
  const variants = {
    enter: (direction: number) => {
      return {
        y: direction > 0 ? 100 : -100,
        opacity: 0,
      };
    },
    center: {
      y: 0,
      opacity: 1,
    },
    exit: (direction: number) => {
      return {
        y: direction < 0 ? 100 : -100,
        opacity: 0,
      };
    },
  };
  return (
    <motion.div
      className={background}
      layout
      custom={1}
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
      transition={{
        type: "tween",
        duration: 0.5,
      }}
    >
      <div className={toolbar}>
        <AllariaMasMobile />
      </div>
      <Container
        className={`d-flex justify-content-center flex-column align-items-center ${successContainer}`}
      >
        <img src={imgLogoAllariaMas} alt="Allaria+" className={logo} />
        <img src={iconSuccess} alt="check" />
        <h3 className="my-4">¡Listo!</h3>
        <p className={description}>
          Tu solicitud de apertura fue registrada con éxito. Te enviaremos un
          mail dentro de 48 horas hábiles confirmando la apertura de la cuenta y
          ayudándote en los primeros pasos para acceder a Allaria+.
        </p>
        <Row className="d-flex justify-content-center w-100 mt-4">
          <Button
            {...buttonCommonProps}
            text="Abrir otra cuenta"
            buttonType="primary"
            click={handleOpenAccount}
            className={`mb-3 mb-xs-0 ${button}`}
          />
          <Button
            {...buttonCommonProps}
            text="Ir a Allaria+"
            buttonType="secondary"
            click={handleClickHome}
            className={`${button}`}
          />
        </Row>
        <p className={footer}>
          Por cualquier consulta, comunicate con nosotros a través de
          hola@allariamas.com.ar o envianos un mensaje por Whatsapp al
          <span> +54 9 11 3849-9171</span>
        </p>
      </Container>
    </motion.div>
  );
};
export default SuccessPage;
