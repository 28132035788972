import { useState, useEffect } from "react";
import { Form } from "formik";
import { Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../app/hooks";
import {
  onUpdateCurrentStep,
  onUpdateStepNoAllariaAccount,
  onUpdateTypeOfOpening,
} from "../../../../reducers/accountOpeningForm";
import { StepOneNoAllariaAccount } from "../../../../reducers/interfaces/types";
import Button from "../../../ui/Button/Button";
import InputCheckbox from "../../../ui/Inputs/InputCheckbox";
import InputTextNumber from "../../../ui/Inputs/InputTextNumber";
import styles from "../Steps.module.scss";
import { stepOneSchema } from "../../../../schemas/steps";
import FormikWrapper from "../../../ui/FormikWrapper/FormikWrapper";
import { authEmail } from "../../../../connectors/connectors";
import { genericError } from "../../../utils/utils";

const StepOneNoAllaria = () => {
  const {
    stepOne,
    checkBox,
    referredSpan,
    referredSpanErr,
    footer,
  } = styles;

  const dispatch = useDispatch();
  const { formWithoutAllariaAccount, isJuridical } = useAppSelector(
    (state) => state.accountOpeningForm
  );
  const [loadingButton, setLoadingButton] = useState<boolean>(false);

  const initialValuesFormik = formWithoutAllariaAccount[1];

  const handleSubmit = (values: StepOneNoAllariaAccount) => {
    setLoadingButton(true);
    authEmail(values.email)
      .then(() => {
        dispatch(onUpdateStepNoAllariaAccount({ step: 1, data: values }));
        dispatch(onUpdateCurrentStep(2));
        setLoadingButton(false);
      })
      .catch(() => {
        setLoadingButton(false);
        genericError();
      });
  };

  const handleClick = () => {
    dispatch(onUpdateTypeOfOpening("WITH_ALLARIA_ACCOUNT"));
  };

  useEffect(() => {
    if(isJuridical){
      dispatch(onUpdateTypeOfOpening("WITH_ALLARIA_ACCOUNT"));
    }

  }, [isJuridical])
  
  return (
    <Container className={stepOne}>
      <FormikWrapper
        initialValues={initialValuesFormik}
        validationSchema={stepOneSchema}
        onSubmit={handleSubmit}
      >
        {({ values, isValid, dirty, errors, touched }) => (
          <Form>
            <Row className="py-3" lg={2}>
              <InputTextNumber
                label="Email personal"
                name="email"
                type="text"
                placeholder="nombre@mail.com"
              />
            </Row>
            <Row className={`${checkBox}`}>
              <InputCheckbox
                label="Fui referido"
                name="userReferred"
                boxcolor="lightblue"
              />
              {values.userReferred && (
                <>
                  <InputTextNumber name="emailOrNameReferred" type="text" />
                  <span
                    className={
                      errors.emailOrNameReferred && touched.emailOrNameReferred
                        ? referredSpanErr
                        : referredSpan
                    }
                  >
                    Ingresá nombre o mail de la persona que te refirió
                  </span>
                </>
              )}
            </Row>
            <Row className="px-2 pt-3 pb-4">
              <Button
                buttonType="primary"
                text="Iniciar apertura"
                type="submit"
                width="100%"
                disabled={!(dirty && isValid) || loadingButton}
                isLoading={loadingButton}
              />
            </Row>
          </Form>
        )}
      </FormikWrapper>
      <p className={`text-center ${footer}`}>
        ¿Ya tenés cuenta en Allaria?{" "}
        <span onClick={handleClick}>Hacé click acá.</span>
      </p>
    </Container>
  );
};

export default StepOneNoAllaria;
