import { addMessage } from "./addMessage";
import styles from "./Utils.module.scss";

const { contactText, toastMessage } = styles;

export const minutesToMiliseconds = (minutes: number) => {
  return minutes * 60 * 1000;
};

export const genericError = () =>
  addMessage({
    message: (
      <span className={toastMessage}>
        Lo sentimos, algo falló inesperadamente. Intentalo nuevamente y si el
        error persiste,{" "}
        <a
          href="https://wa.me/5491138499171"
          target="_blank"
          rel="noreferrer"
          className={contactText}
        >
          contactate con Soporte.
        </a>
      </span>
    ),
    messageId: "localStorageMessage",
    type: "error",
    showOverPage: true,
    position: "top-right",
  });

export const encodeImageToBase64 = (imageFile: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    if (!imageFile) {
      reject("No image file provided");
      return;
    }

    const reader = new FileReader();

    reader.onload = () => {
      if (typeof reader.result === "string") {
        // The split is for removing the type out of the string
        resolve(reader.result.split(",")[1]);
      } else {
        reject("Failed to read image file");
      }
    };

    reader.onerror = () => {
      reject("Error reading image file");
    };

    reader.readAsDataURL(imageFile);
  });
};

export const blobToFile = (blob: Blob, name: string, type: string) => {
  return new File([blob], name, {
    type: type,
  });
};

export const dataURItoBlob = (dataURI: string): Blob => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  let byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  let ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  let ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  let blob = new Blob([ab], { type: mimeString });
  return blob;
};

const dataURIFromFile = async (field: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener("load", () => {
      if (typeof reader.result === "string") {
        resolve(reader.result);
      } else {
        reject("Failed to read image file");
      }
    });

    reader.readAsDataURL(field);
  });
};

const canvasResize = (imgEl: HTMLImageElement, wantedWidth: number): Blob => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const aspect = imgEl.width / imgEl.height;

  canvas.width = wantedWidth;
  canvas.height = wantedWidth / aspect;

  ctx?.drawImage(imgEl, 0, 0, canvas.width, canvas.height);
  const dataUrl = canvas.toDataURL();
  const dataUrlToBlob = dataURItoBlob(dataUrl);
  return dataUrlToBlob;
};

export const resizeFile = async (
  file: File,
  wantedWidth: number,
  type: string
): Promise<File> => {
  const dataUri = await dataURIFromFile(file);

  return new Promise((resolve, reject) => {
    if (!file) {
      reject("No image file provided");
      return;
    }

    const imgEl = new Image();
    imgEl.src = dataUri;

    imgEl.onload = () => {
      resolve(blobToFile(canvasResize(imgEl, wantedWidth), file.name, type));
    };

    imgEl.onerror = () => {
      reject("Error reading image file");
    };
  });
};
