import Form from "react-bootstrap/Form";
import { useField } from "formik";
import styles from "./Inputs.module.scss";
import { useEffect, useRef, useState } from "react";
import { InputError } from "./UtilsInputs";

interface InputTelProps {
  name: string;
  label?: string;
  disabled?: boolean;
  optional?:boolean;
}
type BorderStyles = 'borderSuccess'|'borderError'|'borderDefault'|'borderBlack'

const InputTel = (props: InputTelProps) => {
  const [{ onChange, ...field }, meta, helpers] = useField(props);
  const inputRefs = useRef<HTMLInputElement[] | null[]>([]);
  const [borderStyle, setBorderStyle] = useState<BorderStyles>("borderDefault");
  const metaValue = meta.value.split(' ')
  metaValue[0]= metaValue[0]===""?"":`+${metaValue[0]}`
  const [inputValue, setInputValue] = useState([metaValue[0], metaValue[1]]);
  const { InputTelContainer } = styles;

  const isValid = (value: string) => !!value.match(/^[0-9]*$/) || value === "";

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const id = e.target.id;

    if (id === "0") {
      if (value[0] === "+" ? isValid(value.slice(1)) : isValid(value)) {
        let shortedValue = value.split(" ").join("").slice(0, 4);
        shortedValue =
          value[0] === "+" || value === ""
            ? shortedValue
            : `+${shortedValue.slice(0, 3)}`;
        setInputValue([shortedValue, inputValue[1]]);
        shortedValue.length > 3 && inputRefs?.current?.[1]?.focus();
      }
    } else if (id === "1") {
      if (isValid(value)) {
        const shortedValue = value.split(" ").join("").slice(0, 12);
        setInputValue([inputValue[0], shortedValue]);
      }
    }
  };

  useEffect(() => {
    helpers.setValue(inputValue.join(" ").slice(1));
  }, [inputValue]);

  useEffect(() => {
    helpers.setTouched(false)
    if(meta.value)setBorderStyle('borderSuccess')
  }, []);
  
  const validateBorderStyle=()=>{
    if(inputValue[0]===""&&inputValue[1]===""&& props.optional)setBorderStyle("borderDefault")
    else {
      if(meta.touched&&meta.error)setBorderStyle("borderError")
      if(meta.touched&&!meta.error)setBorderStyle("borderSuccess")
      if(inputValue[0]===""||inputValue[1]==="")setBorderStyle("borderError")
    }
  };

  const handleBlur = () => {
    helpers.setTouched(true)
    validateBorderStyle();
  };
  
  return (
    <Form.Group controlId={props.name} className={InputTelContainer}>
      {props.label && <Form.Label>{props.label}</Form.Label>}
      <div className={`${styles.InputTel} ${styles[borderStyle]}`}>
        <input
          id={`0`}
          name={field.name}
          value={`${inputValue[0]}`}
          onChange={handleChange}
          min={0}
          placeholder="+54"
          ref={(ref) => (inputRefs.current[0] = ref)}
          onFocus={() => setBorderStyle("borderBlack")}
          disabled={props.disabled}
          onBlur={handleBlur}
        />
        <input
          id={`1`}
          name={field.name}
          value={inputValue[1]}
          maxLength={12}
          min={0}
          onChange={handleChange}
          placeholder="Ej 223 5596829"
          ref={(ref) => (inputRefs.current[1] = ref)}
          onFocus={() => setBorderStyle("borderBlack")}
          onBlur={handleBlur}
          disabled={props.disabled}
          onKeyDown={(e) => {
            if (e.key === "Backspace" && inputValue[1].length === 0) {
              inputRefs.current[0]?.focus();
            }
          }}
        />
      </div>
      <InputError touched={meta.touched} error={meta.error}/>
    </Form.Group>
  );
};

export default InputTel;
