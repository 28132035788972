import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { closeModal } from "../../../../../reducers/modalReducer";
import Modal from "../../../../ui/Modal/Modal";
import ModalBodyWrapper from "../../../../ui/Modal/ModalBodyWrapper";
import { Container } from "react-bootstrap";
import Button from "../../../../ui/Button/Button";
import successIcon from "../../../../../assets/img/icon-sucess-filled.svg";

import styles from "./LivenessValidationCard.module.scss";

interface Props {
  qrImage: string;
  scannedQR: boolean;
}

const QRModal = ({ qrImage, scannedQR }: Props) => {
  const {
    modalQRSuccessContainer,
    modalQRContainer,
    qrHeader,
    qrContainer,
    qr,
  } = styles;
  const dispatch = useAppDispatch();

  const handleClose = () => dispatch(closeModal());

  const { modalName } = useAppSelector((state) => state.modal);

  return (
    <>
      {modalName === "modal-qr" && (
        <Modal
          onClose={handleClose}
          closeIcon={{ hide: !!scannedQR, position: [26, 24] }}
        >
          <ModalBodyWrapper>
            {scannedQR ? (
              <Container className={modalQRSuccessContainer}>
                <img src={successIcon} alt="successIcon" />
                <h3>Mantené la página abierta</h3>
                <span>Ya podés validar tu identidad desde el teléfono.</span>
                <Button
                  text="Entendido"
                  buttonType="primary"
                  width="7.5rem"
                  type="button"
                  click={handleClose}
                />
              </Container>
            ) : (
              <Container className={modalQRContainer}>
                <div className={qrHeader}>
                  <h1>Código QR</h1>
                  <span>Escaneá el código para continuar en tu teléfono.</span>
                </div>
                <div className={qrContainer}>
                  <div className={qr}>
                    <img src={qrImage} alt="QR" />
                  </div>
                  <Button
                    text="Cerrar"
                    buttonType="primary"
                    width="7.5rem"
                    type="button"
                    click={handleClose}
                  />
                </div>
              </Container>
            )}
          </ModalBodyWrapper>
        </Modal>
      )}
    </>
  );
};

export default QRModal;
