import * as Yup from "yup";
import { MAX_FILE_SIZE } from "../../components/ui/Inputs/UtilsInputs";

const requiredMessage = "Este campo es obligatorio";
const formatMessage = "El formato es inválido";

const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/heif",
  "image/heic",
  ".heic",
  ".heif",
];

export const stringRequired = Yup.string().required(requiredMessage);
export const string = Yup.string();

const cuitMessage = "El CUIT/CUIL debe contener 11 números.";

export const cuitRequired = Yup.string()
  .trim()
  .matches(/^[0-9]*$/, "El CUIT/CUIL debe contener sólo números")
  .max(11, cuitMessage)
  .min(11, cuitMessage)
  .required(requiredMessage);

export const emailRequired = Yup.string()
  .email(formatMessage)
  .required(requiredMessage);

export const boolean = Yup.boolean();
export const mustBeTrue = Yup.boolean().oneOf([true]);

export const fileRequired = Yup.mixed()
  .required(requiredMessage)
  .test(
    "fileFormat",
    "FORMAT-ERROR",
    (value) =>
      value &&
      (SUPPORTED_FORMATS.includes(value.type) || typeof value === "number")
  )
  .test(
    "fileSize",
    "SIZE-ERROR",
    (value) =>
      value && (value.size < MAX_FILE_SIZE || typeof value === "number")
  );

export const fileNotRequired = Yup.mixed()
  .test(
    "fileFormat",
    "FORMAT-ERROR",
    (value) =>
      value &&
      (SUPPORTED_FORMATS.includes(value.type) || typeof value === "number")
  )
  .test(
    "fileSize",
    "SIZE-ERROR",
    (value) =>
      value && (value.size < MAX_FILE_SIZE || typeof value === "number")
  );

export const cbuRequired = Yup.string()
  .trim()
  .matches(/^[0-9]*$/, "El CBU debe contener sólo números")
  .test(
    "length",
    "El CBU debe contener 22 caracteres",
    (value) => value?.length === 22
  )
  .required("El CBU es requerido para continuar");
