import { FieldMetaProps, useField } from "formik";
import React, { useEffect } from "react"
import { Form } from "react-bootstrap";
import PlacesAutocomplete from 'react-places-autocomplete';
import styles from "./Inputs.module.scss"
import { InputError } from './UtilsInputs';

interface AutocompleteProps {
    type: "locality" | "province"
    name: string
    label?: string
    placeholder?: string;
    getFieldMeta: any;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const Autocomplete = ({type, name, label, placeholder, getFieldMeta, setFieldValue} : AutocompleteProps) => {
    const [field, meta, helpers] = useField(name);
    const [address, setAddress] = React.useState("");
    const [showSuggestions, setShowSuggestions] = React.useState(false);

    useEffect(() => {
      if(field.value)
        if(type === "province" && field.value === "CIUDAD DE BUENOS AIRES"){
          setAddress("CABA");
          setFieldValue("province", "CABA")
        }
        else
          setAddress(field.value);
      else
        setAddress("");
    },[field.value])
    
    const handleSelect = async (value : string, placeId : string) => {
      const terms = value.split(",");
      setAddress(terms[0]);
      helpers.setValue(terms[0]);
      if(type === "province"){
        setFieldValue("locality", "")
      }
    }
    
    const handleChange = (value: string) => {
        setAddress(value);
        if(value !== field.value) {
            helpers.setValue("");
        }
    }
    
    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      field.onBlur(e);
      setShowSuggestions(false);
    }

    const types = {
        "locality": ["locality","sublocality", "administrative_area_level_3", "administrative_area_level_2"],
        "province": ["administrative_area_level_1"]
    }

    const filterData = (value: any) => {
      if(type === "locality"){
        let province = getFieldMeta("province").value.toUpperCase();
        if(province === "CIUDAD AUTÓNOMA DE BUENOS AIRES") province = "CABA";
        if(province){
          if(value.description.toUpperCase().includes(province))
          return true
        else
          return false;
        } 
      }else{
        return true; 
      } 
    }

  return (
    <div>
      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
        searchOptions={{
            componentRestrictions: { country: "ar" },
            types: types[type]
        }}
        highlightFirstSuggestion={true}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <Form.Group controlId={name} className={`custom-form-group ${styles.autoComplete}`}>
            <Form.Label>{label}</Form.Label>
            <Form.Control 
              {...getInputProps({ placeholder })} 
              onBlur={handleBlur}
              onFocus={() => setShowSuggestions(true)}
              isInvalid={!!meta.touched && !!meta.error}
              isValid={!!meta.touched && !meta.error && meta.value !== ""? true : false}
              className={styles.autoCompleteInput}
            />
            {suggestions.length > 0 && showSuggestions && suggestions.filter(filterData).length > 0 && (
              <div className={styles.autoCompleteOptions}>
                {suggestions.filter(filterData).map((suggestion, i) => {
                  const className = suggestion.active ? styles.active : ""
                      return (
                      <p {...getSuggestionItemProps(suggestion)} className={className} key={`${suggestion.id}-${i}`}> 
                        {suggestion.formattedSuggestion.mainText}  
                      </p>
                      );
                })}
              </div>
            )}
            <InputError touched={meta.touched} error={meta.error}/>
          </Form.Group>
        )}
      </PlacesAutocomplete>
    </div>
  )
};

export default Autocomplete;
