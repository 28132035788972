import styles from "./Button.module.scss"; 

const LoadingCircles = () => {
  return(
    <div id={styles["circleG"]}>
      <div id={styles["circleG_1"]} className={styles.circleG}></div>
      <div id={styles["circleG_2"]} className={styles.circleG}></div>
      <div id={styles["circleG_3"]} className={styles.circleG}></div>
    </div>
  );
};

export default LoadingCircles;