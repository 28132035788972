import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  onUpdateCurrentStep,
  onUpdateStepNoAllariaAccount,
} from "../../../../reducers/accountOpeningForm";
import { Form } from "formik";
import InputSelect from "../../../ui/Inputs/InputSelect";
import InputSwitch from "../../../ui/Inputs/InputSwitch";
import {
  Activity,
  Country,
  StepFourNoAllariaAccount,
} from "../../../../reducers/interfaces/types";
import styles from "../Steps.module.scss";
import { stepFourSchema } from "../../../../schemas/steps";
import FormikWrapper from "../../../ui/FormikWrapper/FormikWrapper";
import StepButtons from "../../../ui/StepButtons/StepButtons";
import { useEffect, useState, useRef } from "react";
import {
  getActivities,
  getCountries,
  saveFiscalData,
  saveUserActivities,
  updateUserProfile,
} from "../../../../connectors/connectors";
import { genericError } from "../../../utils/utils";
import InputTextNumber from "../../../ui/Inputs/InputTextNumber";

interface Activities {
  value: number;
  label: string;
}

interface UserProfileDataToSend {
  dependency_relationship: string;
  screen_spot: number;
  bank_account?: { cbu: string };
}

const LOADING_MESSAGE = "Cargando resultados...";
const NOT_FOUND_OPTION_MESSAGE = "Sin resultados";

const StepFour = () => {
  const [activitiesOptions, setActivitiesOptions] = useState([]);
  const [countriesOptions, setCountriesOptions] = useState([]);
  const [argentinaValue, setArgentinaValue] = useState<number>();
  const formikForm = useRef<any>(null);
  const dispatch = useAppDispatch();
  const { formWithoutAllariaAccount, token } = useAppSelector(
    (state) => state.accountOpeningForm
  );
  const { stepFour, StepFourRow, StepFourCol } = styles;
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [noOptionsMessage, setNoOptionsMessage] = useState({
    activities: LOADING_MESSAGE,
    countries: LOADING_MESSAGE,
  });

  useEffect(() => {
    getActivities(token)
      .then((res) => {
        const activities = res.data.results.map((act: Activity) => ({
          value: act.id,
          label: act.name,
        }));
        activities.sort(function (a: Activities, b: Activities) {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });
        setActivitiesOptions(activities);
        setNoOptionsMessage({
          ...noOptionsMessage,
          activities: NOT_FOUND_OPTION_MESSAGE,
        });
      })
      .catch(() => {
        genericError();
      });

    getCountries(token)
      .then((res) => {
        const countries = res.data.results
          .map((country: Country) => ({
            value: country.code,
            label: country.name,
          }))
          .sort((a: any, b: any) => a.value - b.value);
        setArgentinaValue(
          countries.find((country: any) => country.label === "Argentina").value
        );
        setCountriesOptions(countries);
        setNoOptionsMessage({
          ...noOptionsMessage,
          countries: NOT_FOUND_OPTION_MESSAGE,
        });
      })
      .catch(() => {
        genericError();
      });
  }, [token]);

  const dependencyRelationshipOptions = [
    { value: "YES", label: "Sí" },
    { value: "NO_STUDENT", label: "No, soy estudiante" },
    { value: "NO_MONOTAX", label: "No, soy monotributista" },
    { value: "NO_AUTONOMOUS", label: "No, soy autónomo" },
    { value: "NO_RETIRED", label: "No, soy jubilado" },
    { value: "NO_JOB", label: "No, estoy sin empleo actual" },
  ];

  const taxedOutsideTheCountrypOptions = [
    { value: "NO", label: "No" },
    { value: "YES", label: "Sí" },
  ];

  const localStorageValues = formWithoutAllariaAccount[4];
  const initialValues = {
    dependencyRelationship: localStorageValues.dependencyRelationship,
    activityCategory: localStorageValues.activityCategory,
    taxedOutsideTheCountry: localStorageValues.taxedOutsideTheCountry,
    countryOfTaxation: localStorageValues.countryOfTaxation,
    NIF: localStorageValues.NIF,
  };

  const handleSubmit = (values: StepFourNoAllariaAccount) => {
    setLoadingButton(true);
    const userProfileData: UserProfileDataToSend = {
      dependency_relationship: values.dependencyRelationship,
      screen_spot: 5,
    };
    if (
      values.CBU !== "" &&
      values.CBU.length === 22 &&
      values.hasOpenBankAccount === "YES"
    ) {
      userProfileData.bank_account = { cbu: values.CBU };
    }

    Promise.all([
      saveUserActivities(token, {
        activity: parseInt(values.activityCategory),
      }),
      saveFiscalData(token, {
        country_code: values.countryOfTaxation
          ? values.countryOfTaxation
          : argentinaValue,
        fiscal_id: values.NIF ? values.NIF : formWithoutAllariaAccount[3].cuit,
      }),
      updateUserProfile(token, userProfileData),
    ])
      .then(() => {
        dispatch(onUpdateStepNoAllariaAccount({ step: 4, data: values }));
        dispatch(onUpdateCurrentStep(5));
        setLoadingButton(false);
      })
      .catch(() => {
        setLoadingButton(false);
        genericError();
      });
  };

  useEffect(() => {
    if (localStorageValues.dependencyRelationship) {
      formikForm.current.setFieldValue(
        "taxedOutsideTheCountry",
        localStorageValues.taxedOutsideTheCountry
      );
      const fieldsTouchedToTrue = [
        "dependencyRelationship",
        "activityCategory",
        "countryOfTaxation",
        "taxedOutsideTheCountry",
        "NIF",
      ];
      fieldsTouchedToTrue.forEach((field) =>
        formikForm.current.values[field] !== ""
          ? formikForm.current.setFieldTouched(field, true)
          : null
      );
    }
  }, []);

  return (
    <div className={stepFour}>
      <FormikWrapper
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={stepFourSchema}
        innerRef={formikForm}
      >
        {(formik) => (
          <Form>
            <Row className={StepFourRow}>
              <Col md={12} lg={6} className={StepFourCol}>
                <InputSelect
                  name="dependencyRelationship"
                  label="¿Trabajás en relación de dependencia?"
                  options={dependencyRelationshipOptions}
                />
              </Col>
              <Col md={12} lg={6} className={StepFourCol}>
                <InputSelect
                  name="activityCategory"
                  label={
                    formik.values.dependencyRelationship === "YES"
                      ? "¿Cuál es el rubro o actividad de tu empleador?"
                      : "¿Cuál es tu rubro o actividad?"
                  }
                  firstLetterHeader
                  options={activitiesOptions}
                  isSearchable
                  noOptionsMessage={noOptionsMessage.activities}
                />
              </Col>
            </Row>

            <Row className="pt-2 align-items-end">
              <Col md={12} lg={6} className={StepFourCol}>
                <InputSelect
                  name="taxedOutsideTheCountry"
                  label="¿Tributás en un país diferente que Argentina?"
                  options={taxedOutsideTheCountrypOptions}
                  onChange={() => {
                    formik.setFieldValue("countryOfTaxation", "");
                    formik.setFieldTouched("countryOfTaxation", false);
                    formik.setFieldValue("NIF", "");
                    formik.setFieldTouched("NIF", false);
                  }}
                />
              </Col>
              <Col>
                {formik.values.taxedOutsideTheCountry === "YES" && (
                  <InputSelect
                    label="Indicá el país donde tributás"
                    name="countryOfTaxation"
                    options={countriesOptions}
                    isSearchable
                    noOptionsMessage={noOptionsMessage.countries}
                  />
                )}
              </Col>
            </Row>

            <Row>
              <Col lg={6} xl={6} xxl={6}>
                {formik.values.taxedOutsideTheCountry === "YES" && (
                  <InputTextNumber
                    label="Indicá tu NIF"
                    name="NIF"
                    type="number"
                    placeholder="Número de identificación fiscal"
                  />
                )}
              </Col>
            </Row>

            <Row className="pt-2 align-items-end">
              <Col md={12} lg={6} className={StepFourCol}>
                <InputSelect
                  name="hasOpenBankAccount"
                  label="¿Contás con una cuenta bancaria abierta en Argentina?"
                  options={taxedOutsideTheCountrypOptions}
                  onChange={() => {
                    formik.setFieldValue("CBU", "");
                    formik.setFieldTouched("CBU", false);
                  }}
                />
              </Col>
              <Col>
                {formik.values.hasOpenBankAccount === "YES" && (
                  <InputTextNumber
                    label="Indicá tu CBU/CVU"
                    name="CBU"
                    type="text"
                    placeholder="CBU/CVU"
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const numericValue = inputValue.replace(/\D/g, "");
                      formik.setFieldValue("CBU", numericValue);
                    }}
                  />
                )}
              </Col>
            </Row>

            <StepButtons
              type="submit"
              text="Guardar y Continuar"
              disabled={
                Object.keys(formik.errors).length > 0 ||
                !Object.values(formik.values).some((value) => !!value) ||
                loadingButton
              }
              isLoading={loadingButton}
            />
          </Form>
        )}
      </FormikWrapper>
    </div>
  );
};

export default StepFour;
