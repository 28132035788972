export const stepsWithoutAllariaAccount = [
    {
        "title": "Paso 1",
        "subtitle": "Iniciar apertura"
    },
    {
        "title": "Paso 2",
        "subtitle": "Validación de email"
    },
    {
        "title": "Paso 3",
        "subtitle": "Datos personales"
    },
    {
        "title": "Paso 4",
        "subtitle": "Datos laborales"
    },
    {
        "title": "Paso 5",
        "subtitle": "Perfil de inversor"
    }
];

export const stepsWithAllariaAccount = [
    {
        "title": "Paso 1",
        "subtitle": "Datos personales"
    },
    {
        "title": "Paso 2",
        "subtitle": "Validación de email"
    }
];