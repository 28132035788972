import { FormikValues, Form } from "formik";
import { Col, Container, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import InputCheckbox from "../../../ui/Inputs/InputCheckbox";
import InputSelect from "../../../ui/Inputs/InputSelect";
import {
  getFormatData,
  getOptions,
  getTooltipText,
  getQuestion,
} from "./utils/stepsUtils";
import styles from "../Steps.module.scss";
import { onUpdateCompletedForm } from "../../../../reducers/accountOpeningForm";
import InputTextNumber from "../../../ui/Inputs/InputTextNumber";
import { stepFiveSchema } from "../../../../schemas/steps";
import FormikWrapper from "../../../ui/FormikWrapper/FormikWrapper";
import StepButtons from "../../../ui/StepButtons/StepButtons";
import {
  getChoices,
  getQuestions,
  setUserChoices,
  updateUserProfile,
} from "../../../../connectors/connectors";
import { useEffect, useState } from "react";
import { genericError } from "../../../utils/utils";
import {
  ChoicesStepFive,
  QuestionsStepFive,
} from "../../../../reducers/interfaces/types";

const StepFive = () => {
  const { description, subtitle, text, stepFive } = styles;
  const { formWithoutAllariaAccount, token } = useAppSelector(
    (state) => state.accountOpeningForm
  );
  const dispatch = useAppDispatch();
  const [questions, setQuestions] = useState<QuestionsStepFive[]>([]);
  const [choices, setChoices] = useState<ChoicesStepFive[]>([]);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const initialValuesFormik = formWithoutAllariaAccount[5];

  const handleSubmit = (values: FormikValues) => {
    setLoadingButton(true);
    const data = choices.length > 0 && getFormatData(values);

    if (data)
      setUserChoices(token, data)
        .then(() => {
          updateUserProfile(token, {
            accepted_term_conditions: true,
            other_fund_origin: values.descriptionOfSourceFunds,
          })
            .then(() => {
              setLoadingButton(false);
              dispatch(onUpdateCompletedForm(true));
            })
            .catch(() => {
              setLoadingButton(false);
              genericError();
            });
        })
        .catch(() => {
          setLoadingButton(false);
          genericError();
        });
  };

  useEffect(() => {
    getChoices(token)
      .then((res) => {
        setChoices(res.data.results);
      })
      .catch(() => {
        genericError();
      });

    getQuestions(token)
      .then((res) => {
        setQuestions(res.data.results);
      })
      .catch(() => {
        genericError();
      });
  }, []);

  return (
    <div className={stepFive}>
      <p className={description}>
        El saldo disponible en tu Allaria+ será invertido automáticamente en un
        Fondo Común de Inversión de liquidez inmediata (Money Market)
        administrado por nuestro socio comercial, Allaria. <br /> ¡No tiene
        costo por operación y tu dinero siempre estará disponible para utilizar!
      </p>
      <p className={subtitle}>Completá las siguientes preguntas requeridas.</p>
      {questions.length > 0 && choices.length > 0 && (
        <FormikWrapper
          initialValues={initialValuesFormik}
          onSubmit={handleSubmit}
          validationSchema={stepFiveSchema}
        >
          {({ values, isValid, dirty, setFieldValue, setFieldTouched }) => (
            <Form>
              <Row className="align-items-end">
                <Col xs={12} md={6}>
                  <InputSelect
                    name="capitalMarketKnowledge"
                    label={getQuestion(questions, 1)?.question_text}
                    options={getOptions(choices, 1)}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <InputSelect
                    name="investmentObjective"
                    label={getQuestion(questions, 2)?.question_text}
                    options={getOptions(choices, 2)}
                  />
                </Col>
              </Row>
              <Row className="align-items-end mt-md-3">
                <Col xs={12} md={6}>
                  <InputSelect
                    name="investmentTerm"
                    label={getQuestion(questions, 3)?.question_text}
                    options={getOptions(choices, 3)}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <InputSelect
                    name="percentageOfSavingsToBeInvested"
                    label={getQuestion(questions, 4)?.question_text}
                    options={getOptions(choices, 4)}
                  />
                </Col>
              </Row>
              <Row className="align-items-end mt-md-3">
                <Col xs={12} md={6}>
                  <InputSelect
                    name="investmentRisk"
                    label={getQuestion(questions, 5)?.question_text}
                    options={getOptions(choices, 5)}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <InputSelect
                    name="sourceOfFunds"
                    label={getQuestion(questions, 6)?.question_text}
                    options={getOptions(choices, 6)}
                    onChange={() => {
                      setFieldValue("descriptionOfSourceFunds", "");
                      setFieldTouched("descriptionOfSourceFunds", false);
                    }}
                  />
                </Col>
              </Row>
              {values.sourceOfFunds === 33 && (
                <Row className="mt-md-2">
                  <InputTextNumber
                    name="descriptionOfSourceFunds"
                    type="text"
                    placeholder="Describí el origen de los fondos"
                  />
                </Row>
              )}
              <Row className="align-items-end mt-md-3 ">
                <Col xs={12} md={6} className="position-relative pb-3">
                  <InputSelect
                    name="monthlyIncome"
                    label={getQuestion(questions, 7)?.question_text}
                    options={getOptions(choices, 7)}
                  />
                  {values?.monthlyIncome === 22 && (
                    <p className={text}>
                      *Envianos la documentación adjunta que acredite el origen
                      de los fondos a{" "}
                      <span className="text-primary">
                        hola@allariamas.com.ar
                      </span>
                    </p>
                  )}
                </Col>
                <Col xs={12} md={6} className="pb-3">
                  <InputSelect
                    name="totalEquity"
                    label={getQuestion(questions, 8)?.question_text}
                    options={getOptions(choices, 8)}
                  />
                </Col>
              </Row>
              <Container fluid className="mt-4 p-0">
                <InputCheckbox
                  boxcolor="primary"
                  name="termAndConditions"
                  label={
                    <p>
                      Acepto los{" "}
                      <a
                        href={`${process.env.REACT_APP_STATICURL}/terms/2023-07-11/TyC+Allaria%2B+Cuenta+con+Anexo+I+sin+firma.pdf`}
                        rel="noreferrer"
                        target="_blank"
                        className="text-decoration-none"
                      >
                        <span>Términos y Condiciones</span>
                      </a>
                    </p>
                  }
                />
              </Container>
              <Row className={styles.buttonsRowStepFive}>
                <StepButtons
                  type="submit"
                  text="Confirmar"
                  disabled={!(isValid && dirty) || loadingButton}
                  isLoading={loadingButton}
                />
              </Row>
            </Form>
          )}
        </FormikWrapper>
      )}
    </div>
  );
};

export default StepFive;
