import { useField } from "formik";
import React from "react";
import { Form } from "react-bootstrap";
import { InputError } from "./UtilsInputs";

interface InputCuitProps {
  name: string;
  label: string;
  placeholder?: string;
  disabled?:boolean
}

const InputCuit = ({ name, label, placeholder, disabled }: InputCuitProps) => {
  const [field, meta, helpers] = useField({ name });
  const [inputValue, setInputValue] = React.useState(field.value ? field.value.slice(0,2) + "-" + field.value.slice(2,10) + "-" + field.value.slice(10) : "");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const isValid = value === "" || value.replaceAll("-", "").match("^[0-9]+$");

    if (isValid) {
      if (value.charAt(value.length - 1) === "-") {
        setInputValue(value.slice(0, -1));
      } else if (value.length === 3) {
        setInputValue(value.slice(0, 2) + "-" + value.slice(2));
      } else if (value.length === 12) {
        setInputValue(value.slice(0, 11) + "-" + value.slice(11));
      } else {
        setInputValue(value);
      }
      helpers.setValue(value.replaceAll("-", ""));
    }
  };

  return (
    <div>
      <Form.Group controlId={name} className={`custom-form-group`}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          {...field}
          value={inputValue}
          onBlur={field.onBlur}
          type="string"
          placeholder={placeholder}
          onChange={handleChange}
          maxLength={13}
          isInvalid={!!meta.touched && !!meta.error}
          isValid={!!meta.touched && !meta.error && meta.value !== ""? true : false}
          disabled={disabled}
        />
        <InputError touched={meta.touched} error={meta.error}/>
      </Form.Group>
    </div>
  );
};

export default InputCuit;
