import { Col, Container, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import dorsoDNI from "../../../assets/img/DNI-dorso.png";
import frenteDNI from "../../../assets/img/DNI-frente.png";
import { closeModal } from "../../../reducers/modalReducer";
import Button from "../Button/Button";
import Modal from "../Modal/Modal";
import ModalBodyWrapper from "../Modal/ModalBodyWrapper";
import styles from "./modalDNI.module.scss";

const ModalDNI = () => {
  const { dniContainer, title, description, subtitle } = styles;
  const dispatch = useAppDispatch();

  const handleClick = () => dispatch(closeModal());
  const { modalName } = useAppSelector((state) => state.modal);

  return (
    <>
    {modalName === "modalDNI" && (
    <Modal onClose={() => dispatch(closeModal())}>
      <ModalBodyWrapper maxWidth={678}>
        <Container className={dniContainer}>
          <p className={title}>Ejemplos de Foto DNI</p>
          <p className={description}>
            El objetivo debe estar recto sin inclinaciones y debe enfocar el
            centro del DNI. Lo más importante es intentar que los márgenes de la
            fotografía coincida con los límites del documento oficial, solo
            recorda dejar un pequeño margen para que no se corte la imagen.
          </p>
          <Row className="justify-content-center">
            <Col sm={10} lg={6} className="pb-3 pb-lg-0">
              <p className={subtitle}>Foto - Frente del DNI</p>
              <img src={frenteDNI} alt="Frente dni" />
            </Col>
            <Col sm={10} lg={6}>
              <p className={subtitle}>Foto - Dorso del DNI</p>
              <img src={dorsoDNI} alt="Dorso dni" />
            </Col>
          </Row>
          <Col
            xs={12}
            sm={8}
            md={6}
            lg={4}
            className="justify-content-center pt-4"
          >
            <Button
              buttonType="primary"
              text="Listo"
              type="button"
              click={handleClick}
              className="w-100"
            />
          </Col>
        </Container>
      </ModalBodyWrapper>
    </Modal>
    )}
    </>
  );
};

export default ModalDNI;
