import React from "react";
import { motion } from "framer-motion";

interface Props {
  children: React.ReactElement;
  maxWidth?: number;
  className?: string;
}

const modalContent = {
  hidden: { opacity: 0, transition: { duration: 0.1 } },
  visible: { opacity: 1, transition: { duration: 0.1, delay: 0.2 } },
};

const ModalBodyWrapper = ({ children, maxWidth, className }: Props) => {
  return (
    <motion.div
      layout="position"
      variants={modalContent}
      style={maxWidth ? { maxWidth: `${maxWidth}px` } : undefined}
      className={`${className ?? ""}`}
    >
      {children}
    </motion.div>
  );
};

export default ModalBodyWrapper;
