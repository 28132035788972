import React, { ReactElement } from "react";
import styles from "./Button.module.scss";
import LoadingCircles from "./LoadingCircles";

interface ButtonProps {
  text: string;
  buttonType: "primary" | "secondary" | "tertiary";
  type: "button" | "submit";
  disabled?: boolean;
  click?: React.MouseEventHandler<HTMLButtonElement>;
  leftMargin?: boolean;
  width?: string;
  className?: string;
  isLoading?: boolean;
  icon?: ReactElement;
  error?: boolean;
}

const Button = (props: ButtonProps) => {
  const { leftMarginButton } = styles;
  const buttonClass = props.isLoading
    ? `${props.buttonType}Loading`
    : props.buttonType;

  return (
    <button
      className={`${styles[buttonClass]} ${props.error ? styles.error : ""} ${
        props.leftMargin ? leftMarginButton : "ml-0"
      } ${props.className && props.className}`}
      disabled={props.disabled}
      onClick={props.click}
      onMouseDown={(e) => e.preventDefault()}
      style={props.width ? { width: props.width } : {}}
      type={props.type}
    >
      {props.isLoading ? (
        <LoadingCircles />
      ) : (
        <div className={styles.buttonContent}>
          <p>{props.text}</p>
          {props.icon ?? null}
        </div>
      )}
    </button>
  );
};

export default Button;
