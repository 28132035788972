import { Col, Container, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { onResetForm } from "../../../reducers/accountOpeningForm";
import { closeModal } from "../../../reducers/modalReducer";
import Button from "../Button/Button";
import Modal from "../Modal/Modal";
import ModalBodyWrapper from "../Modal/ModalBodyWrapper";
import { persistor } from "../../../index";
import styles from "./CancelOpeningModal.module.scss";

const CancelOpeningModal = () => {
  const { modalContainer, title, description } = styles;
  const dispatch = useAppDispatch();
  const handleClick = () => dispatch(closeModal());
  const handleCancel = async () => {
    dispatch(onResetForm());
    persistor.pause();
    persistor.flush().then(() => {
      return persistor.purge();
    });
    localStorage.clear();
    window.location.href = "https://allariamas.com.ar";
  };
  const { modalName } = useAppSelector((state) => state.modal);

  return (
    <>
      {modalName === "modal-cancel" && (
        <Modal onClose={handleClick}>
          <ModalBodyWrapper maxWidth={600}>
            <Container className={modalContainer}>
              <p className={title}>¿Cancelar apertura?</p>
              <p className={description}>
                Estás por cancelar la apertura de cuenta en Allaria+. Si
                cancelás tus datos no serán guardados.
              </p>
              <Row className="w-100 d-flex justify-content-start">
                <Col className="p-0">
                  <Button
                    buttonType="primary"
                    width="163px"
                    text="Sí, cancelar"
                    type="button"
                    click={handleCancel}
                  />
                  <Button
                    buttonType="secondary"
                    leftMargin
                    width="163px"
                    text="No, volver"
                    type="button"
                    click={handleClick}
                  />
                </Col>
              </Row>
            </Container>
          </ModalBodyWrapper>
        </Modal>
      )}
    </>
  );
};

export default CancelOpeningModal;
