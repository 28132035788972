import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { onUpdateCurrentStep } from "../../../reducers/accountOpeningForm";
import Step from "./Step";
import styles from "./stepper.module.scss";
import {
  stepsWithoutAllariaAccount,
  stepsWithAllariaAccount,
} from "./utils/stepsUtils";

const Stepper = () => {
  const dispatch = useAppDispatch();
  const { typeOfOpening, currentStep } = useAppSelector(
    (state) => state.accountOpeningForm
  );
  const { stepper } = styles;

  const steps =
    typeOfOpening === "WITHOUT_ALLARIA_ACCOUNT"
      ? stepsWithoutAllariaAccount
      : stepsWithAllariaAccount;
  const searchStateStep = (i: number) => {
    let stepperState: "active" | "fill" | "disabled";
    if (currentStep === i + 1) stepperState = "active";
    else if (currentStep < i + 1) stepperState = "disabled";
    else stepperState = "fill";
    return stepperState;
  };

  const handleClick = (i: number) => {
    if (currentStep === 4 || currentStep === 5) {
      dispatch(onUpdateCurrentStep(i + 1));
    }
  };

  return (
    <div
      className={`d-flex flex-md-column justify-content-center ml-2 ${stepper}`}
    >
      {steps.map((step, i) => (
        <Step
          {...step}
          currentStep={searchStateStep(i)}
          key={step.title}
          handleClick={i === 2 || i === 3 ? () => handleClick(i) : undefined}
        />
      ))}
    </div>
  );
};
export default Stepper;
