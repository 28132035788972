import { ReactComponent as FillDot } from "../../../assets/img/fillDotIcon.svg";
import { ReactComponent as ActiveDot } from "../../../assets/img/activeDotIcon.svg";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { ReactComponent as BackArrowIcon } from "../../../assets/img/backArrowIcon.svg";
import {
  onUpdateCurrentStep,
  onUpdateStepNoAllariaAccount,
  onUpdateTypeOfOpening,
} from "../../../reducers/accountOpeningForm";
import styles from "./stepHeader.module.scss";

const StepHeader = () => {
  const { container, arrowStyles, titleStyles, dotStyles } = styles;

  const { typeOfOpening, currentStep, formWithoutAllariaAccount } =
    useAppSelector((state) => state.accountOpeningForm);
  const { windowWidth } = useAppSelector((state) => state);
  const isMobile = windowWidth.width < 768;

  const dispatch = useAppDispatch();

  const stepThreeProgress = formWithoutAllariaAccount[3].currentStepProgress;

  const handleGoBack = () => {
    if (currentStep === 3) {
      if (stepThreeProgress === 2) {
        const data = {
          ...formWithoutAllariaAccount[3],
          currentStepProgress: 1,
        };
        dispatch(onUpdateStepNoAllariaAccount({ step: 3, data }));
      } else {
        const data = {
          ...formWithoutAllariaAccount[3],
          currentStepProgress: 2,
        };
        dispatch(onUpdateStepNoAllariaAccount({ step: 3, data }));
      }
    } else if (currentStep === 1 && typeOfOpening === "WITH_ALLARIA_ACCOUNT") {
      dispatch(onUpdateTypeOfOpening("WITHOUT_ALLARIA_ACCOUNT"));
    } else {
      dispatch(onUpdateCurrentStep(currentStep - 1));
    }
  };

  const getStepTitle = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            Abrí tu cuenta en <b>Allaria+</b>
          </>
        );
      case 3:
        return stepThreeProgress === 2
          ? "Validá tu identidad"
          : "Completá tus datos personales";
      case 4:
        return "Completá tus datos laborales";
      case 5:
        return "¡En Allaria+ tu saldo rinde todos los días sin que hagas nada!";
      default:
        return "";
    }
  };

  const showBackArrow =
    (currentStep === 1 && typeOfOpening === "WITH_ALLARIA_ACCOUNT") ||
    (currentStep === 3 &&
      formWithoutAllariaAccount[3].currentStepProgress !== 1) ||
    currentStep > 3;

  return (
    <>
      {getStepTitle() && (
        <div className={container}>
          <div className="d-flex align-items-center">
            {showBackArrow && (
              <BackArrowIcon className={arrowStyles} onClick={handleGoBack} />
            )}
            <h2 className={titleStyles}>{getStepTitle()}</h2>
          </div>
          {typeOfOpening === "WITHOUT_ALLARIA_ACCOUNT" &&
            currentStep === 3 &&
            !isMobile && (
              <div className="d-flex">
                {formWithoutAllariaAccount[3].currentStepProgress === 1 ? (
                  <FillDot className="mx-1" />
                ) : (
                  <ActiveDot
                    onClick={handleGoBack}
                    className={`mx-1 ${dotStyles}`}
                  />
                )}
                {formWithoutAllariaAccount[3].currentStepProgress === 2 ? (
                  <FillDot className="mx-1" />
                ) : (
                  <ActiveDot className="mx-1" />
                )}
                {formWithoutAllariaAccount[3].currentStepProgress === 3 ? (
                  <FillDot className="mx-1" />
                ) : (
                  <ActiveDot className="mx-1" />
                )}
              </div>
            )}
        </div>
      )}
    </>
  );
};

export default StepHeader;
