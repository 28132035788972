import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { Container } from "react-bootstrap";
import Modal from "../../../../ui/Modal/Modal";
import ModalBodyWrapper from "../../../../ui/Modal/ModalBodyWrapper";
import Button from "../../../../ui/Button/Button";
import styles from "./MinimumRequirementsModal.module.scss";
import { closeModal } from "../../../../../reducers/modalReducer";
import InfoAlert from "../../../../ui/InfoAlert/InfoAlert";

const MinimumRequirementsModal = () => {
  const { modalContainer, title, subtitle, description, infoAlertClassName } =
    styles;

  const dispatch = useAppDispatch();

  const handleClose = () => dispatch(closeModal());

  const { modalName } = useAppSelector((state) => state.modal);

  return (
    <>
      {modalName === "modal-minimum-requirements" && (
        <Modal
          onClose={handleClose}
          closeIcon={{ hide: false, position: [26, 24] }}
        >
          <ModalBodyWrapper maxWidth={520}>
            <Container className={modalContainer}>
              <h1 className={title}>Requisitos mínimos</h1>
              <p className={description}>
                Para validar tu identidad, la cámara y buscador de internet
                deben cumplir estas condiciones.
              </p>
              <h3 className={subtitle}>Cámara:</h3>
              <ul>
                <li>Resolución: 320x240 píxeles</li>
                <li>Tamaño: 4 pulgadas</li>
                <li>Cuadros por segundo (FPS): 15</li>
                <li>Tasa de refresco: 60Hz</li>
                <li>Distinción de colores: sí</li>
                <li>Cámara virtual o software: no</li>
              </ul>
              <InfoAlert
                text="Si es webcam, montala encima de la pantalla."
                containerClassName={infoAlertClassName}
              />
              <h3 className={subtitle}>Buscador de internet:</h3>
              <ul>
                <li>
                  <a href="https://support.google.com/chrome/answer/95346?hl=es&co=GENIE.Platform%3DDesktop">
                    Google Chrome
                  </a>
                </li>
                <li>
                  <a href="https://www.mozilla.org/es-AR/firefox/new/">
                    Mozilla Firefox
                  </a>
                </li>
                <li>
                  <a href="https://www.microsoft.com/es-es/edge/download?form=MA13FJ&ch=1">
                    Microsoft Edge
                  </a>
                </li>
                <li>
                  <a href="https://www.apple.com/la/safari/">Safari</a>
                </li>
              </ul>
              <InfoAlert
                text="Actualizá tu buscador a su última versión para asegurar que funcione correctamente."
                containerClassName={infoAlertClassName}
              />
              <Button
                buttonType="primary"
                width="7.5rem"
                text="Cerrar"
                type="button"
                click={handleClose}
              />
            </Container>
          </ModalBodyWrapper>
        </Modal>
      )}
    </>
  );
};

export default MinimumRequirementsModal;
