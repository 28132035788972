import { ReactComponent as AllariaMas } from "../../../assets/img/allaria-plus-logo.svg";
import { ReactComponent as AllariaMasMobile } from "../../../assets/img/allaria-plus-logo-white.svg";
import Stepper from "../Stepper/Stepper";
import Footer from "./Footer";
import styles from "./layout.module.scss";
import { motion } from "framer-motion";
import StepHeader from "../StepHeader/StepHeader";
import { useAppSelector } from "../../../app/hooks";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  const { animationDirection, isLoading } = useAppSelector(
    (state) => state.accountOpeningForm
  );
  const { wrapperGrid, stepper, form, daruma, background, toolbar, header } =
    styles;

  const variants = {
    enter: (direction: number) => {
      return {
        y: direction > 0 ? 100 : -100,
        opacity: 0,
      };
    },
    center: {
      y: 0,
      opacity: 1,
    },
    exit: (direction: number) => {
      return {
        y: direction < 0 ? 100 : -100,
        opacity: 0,
      };
    },
  };

  return (
    <div className={background}>
      <div className={toolbar}>
        <AllariaMasMobile />
      </div>
      <motion.div className={wrapperGrid} layout>
        <a href="https://allariamas.com.ar/">
          <AllariaMas className={daruma} />
        </a>
        <div className={stepper}>
          <Stepper />
        </div>
        <div className={header}>
          {!isLoading && (
            <motion.div
              layout
              custom={animationDirection}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                type: "tween",
                duration: 0.5,
              }}
              className={styles.content}
            >
              <StepHeader />
            </motion.div>
          )}
        </div>
        <div className={form}>{children}</div>
        <Footer />
      </motion.div>
    </div>
  );
};

export default Layout;
