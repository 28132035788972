import { Col, Container } from 'react-bootstrap'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import tramitDNI from "../../../assets/img/DNI-tramit.png"
import { closeModal } from '../../../reducers/modalReducer'
import Button from '../Button/Button'
import Modal from '../Modal/Modal'
import ModalBodyWrapper from '../Modal/ModalBodyWrapper'
import styles from "./modalDNI.module.scss"

const ModalDNITramit = () => {

    const { dniContainer, title, subtitle, description } = styles;
    const dispatch = useAppDispatch();
    const {modalName} = useAppSelector((state) => state.modal);

    const handleClick = () => dispatch(closeModal());

    return (
        <>
        {modalName === "modalProcessNumber" &&
            <Modal onClose={() => dispatch(closeModal())}>
                <ModalBodyWrapper maxWidth={678} >
                    <Container className={dniContainer}>
                        <p className={title}>¿Dónde encuentro el Nro de Tramite?</p>
                        <p className={description}>Es un número de once dígitos que se encuentra en la parte inferior del DNI, debajo de la fecha de vencimiento del mismo, junto al número de documento, y figura como “Trámite N°/ Of.ident.”.
                            En el documento anterior, se encuentra en el dorso del mismo, arriba de la firma del Ministro del Interior.</p>
                        <p className={subtitle}>Foto - Frente del DNI</p>
                        <img src={tramitDNI} alt="Nro tramite dni" />
                        <Col xs={12} sm={8} md={6} lg={4} className='justify-content-center pt-4'>
                            <Button buttonType='primary' text='Listo' type='button' click={handleClick} className="w-100"/>
                        </Col>
                    </Container>
                </ModalBodyWrapper>
            </Modal>
        }
        </>
    )
}

export default ModalDNITramit