import { Formik, FormikProps } from "formik";

interface Props {
    children: (formik: FormikProps<any>) => React.ReactNode;
    initialValues: any;
    validationSchema: any;
    onSubmit: ( values: any ) => void;
    innerRef?:any
}


const FormikWrapper = ({ children, validationSchema, initialValues, onSubmit, innerRef }: Props) => {
  return (
    <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        innerRef={innerRef}
        validateOnChange
    >
        {children}
    </Formik>
  )
};

export default FormikWrapper;
