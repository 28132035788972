import { useState } from "react";
import { Form } from "formik";
import { Col, Container, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  onUpdateCurrentStep,
  onUpdateStepAllariaAccount,
  onUpdateStepAllariaAccountJuridical,
} from "../../../../reducers/accountOpeningForm";
import Button from "../../../ui/Button/Button";
import InputCheckbox from "../../../ui/Inputs/InputCheckbox";
import InputTextNumber from "../../../ui/Inputs/InputTextNumber";
import InputTel from "../../../ui/Inputs/InputTel";
import styles from "../Steps.module.scss";
import {
  StepOneAllariaAccount,
  StepOneJuridicalAllariaAccount,
} from "../../../../reducers/interfaces/types";
import { openModal } from "../../../../reducers/modalReducer";
import {
  stepOneAllariaSchema,
  stepOneJuridicalAllariaSchema,
} from "../../../../schemas/steps";
import FormikWrapper from "../../../ui/FormikWrapper/FormikWrapper";
import { authEmail } from "../../../../connectors/connectors";
import { genericError } from "../../../utils/utils";
import Tooltip from "../../../ui/Tooltip/Tooltip";
import infoBlueIcon from "../../../../assets/img/Inputs/infoBlueIcon.svg";
import InputSelect from "../../../ui/Inputs/InputSelect";

const StepOneAllaria = () => {
  const { stepOne, checkBox, referredSpan, referredSpanErr, referredInput } =
    styles;

  const dispatch = useAppDispatch();

  const {
    formWithAllariaAccount,
    formWithAllariaAccountJuridical,
    isJuridical,
  } = useAppSelector((state) => state.accountOpeningForm);

  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const initialValuesFormik = isJuridical
    ? formWithAllariaAccountJuridical[1]
    : formWithAllariaAccount[1];

  const handleSubmit = (values: StepOneAllariaAccount) => {
    setLoadingButton(true);
    authEmail(values.personalMail)
      .then(() => {
        dispatch(onUpdateStepAllariaAccount({ step: 1, data: values }));
        dispatch(onUpdateCurrentStep(2));
        setLoadingButton(false);
      })
      .catch(() => {
        setLoadingButton(false);
        genericError();
      });
  };

  const handleClickDetail = () => {
    dispatch(openModal({ name: "modal-note" }));
  };

  const handleJuridicalSubmit = (values: StepOneJuridicalAllariaAccount) => {
    setLoadingButton(true);
    authEmail(values.email)
      .then(() => {
        dispatch(
          onUpdateStepAllariaAccountJuridical({ step: 1, data: values })
        );
        dispatch(onUpdateCurrentStep(2));
        setLoadingButton(false);
      })
      .catch(() => {
        setLoadingButton(false);
        genericError();
      });
  };

  const companyTypeOptions = [
    { value: "SA", label: "SA - Sociedad Anónima" },
    { value: "SRL", label: "SRL - Sociedad de Responsabilidad Limitada" },
    {
      value: "COOP",
      label: "Asociación / Fundación / Cooperativa / Cámara",
    },
    { value: "FID", label: "Fideicomiso" },
    { value: "SAS", label: "SAS - Sociedad por Acciones Simplificada" },
    { value: "SGR", label: "SGR - Sociedad de Garantía Recíproca" },
    { value: "CONS", label: "Consorcio" },
    { value: "OTROS", label: "Otros" },
  ];

  return (
    <Container className={`${stepOne} ${isJuridical ? "pb-5" : ""}`}>
      {isJuridical ? (
        <FormikWrapper
          initialValues={initialValuesFormik}
          validationSchema={stepOneJuridicalAllariaSchema}
          onSubmit={handleJuridicalSubmit}
        >
          {({ values, isValid, dirty, errors, touched }) => (
            <Form>
              {/* Emails */}
              <Row>
                <Col lg={6} className="pb-2 pb-lg-0">
                  <InputTextNumber
                    label="Email"
                    name="email"
                    type="text"
                    placeholder="nombre@mail.com"
                  />
                </Col>
                <Col lg={6} className="pb-2 pb-lg-0">
                  <InputTextNumber
                    label="Mi mail registrado en Allaria es"
                    name="allariaEmail"
                    type="text"
                    placeholder="nombre@mail.com"
                  />
                </Col>
              </Row>
              {/* Business name and cuit */}
              <Row>
                <Col lg={6} className="pb-2 pb-lg-0">
                  <InputTextNumber
                    label="Razón social"
                    name="businessName"
                    type="text"
                    placeholder=""
                  />
                </Col>
                <Col>
                  <InputTextNumber
                    label="CUIT"
                    name="businessCuit"
                    placeholder="Sin puntos"
                    type="number"
                    maxLength={11}
                  />
                </Col>
              </Row>
              <h5>Datos del representante</h5>
              {/* Representative name and lastname */}
              <Row className="mt-md-3">
                <Col lg={6} className="pb-2 pb-lg-0">
                  <InputTextNumber
                    label="Nombre/s"
                    name="representativeName"
                    type="text"
                    placeholder=""
                  />
                </Col>
                <Col lg={6} className="pb-2 pb-lg-0">
                  <InputTextNumber
                    label="Apellido/s"
                    name="representativeLastName"
                    type="text"
                    placeholder=""
                  />
                </Col>
              </Row>
              {/* Representative dni and tel */}
              <Row className="mt-md-3">
                <Col lg={6} className="pb-2 pb-lg-0">
                  <InputTextNumber
                    label="DNI"
                    name="representativeDni"
                    placeholder="Sin puntos"
                    type="number"
                    maxLength={8}
                  />
                </Col>
                <Col lg={6} className="pb-2 pb-lg-0">
                  <InputTel label="Teléfono" name="representativeTel" />
                </Col>
              </Row>
              <h5>Datos de la empresa</h5>
              {/* Representative name and lastname */}
              <Row className="mt-md-3">
                <Col lg={6} className="pb-2 pb-lg-0">
                  <InputSelect
                    name="companyType"
                    label="Tipo societario"
                    options={companyTypeOptions}
                  />
                </Col>
                <Col lg={6} className="d-flex justify-center align-center">
                  <InputCheckbox
                    label="Es entidad pública"
                    name="publicEntity"
                    boxcolor="primary"
                  />
                </Col>
              </Row>
              {/* Referred and joint signature */}
              <Row
                className={`mt-md-3 mt-2 d-flex ${
                  values.userReferred
                    ? "align-items-start"
                    : "align-items-center"
                }`}
              >
                <Col lg={6} className="pb-2 pb-lg-0">
                  <InputCheckbox
                    label="Fui referido"
                    name="userReferred"
                    boxcolor="primary"
                  />
                  {values.userReferred && (
                    <div className={referredInput}>
                      <InputTextNumber name="emailOrNameReferred" type="text" />
                      <div
                        className={
                          errors.emailOrNameReferred &&
                          touched.emailOrNameReferred
                            ? referredSpanErr
                            : referredSpan
                        }
                      >
                        <span>
                          Ingresá nombre o mail de la persona que te refirió
                        </span>
                      </div>
                    </div>
                  )}
                </Col>
                <Col lg={6} className="pb-2 pb-lg-0">
                  <InputCheckbox
                    label={
                      <>
                        <label className="me-2">Tengo firma conjunta</label>
                        <Tooltip
                          text="Es un tipo de firma que la empresa posee y exige el acuerdo de todos o algunos de los titulares presentes en el estatuto o poder para realizar una operación."
                          position="top"
                          interactive
                        >
                          <img src={infoBlueIcon} alt="icon-tooltip" />
                        </Tooltip>
                      </>
                    }
                    name="hasJointSignature"
                    boxcolor="primary"
                  />
                </Col>
              </Row>
              {/* Term and conditions and allaria file sharing */}
              <Row className={`mt-5 ${checkBox}`}>
                <Col lg={6} className="pb-2 pb-lg-0">
                  <InputCheckbox
                    label={
                      <p>
                        Acepto los{" "}
                        <a
                          href={`${process.env.REACT_APP_STATICURL}/terms/2023-07-11/TyC+Allaria%2B+Cuenta+con+Anexo+I+sin+firma.pdf`}
                          target="_blank"
                          rel="noreferrer"
                          className="text-decoration-none"
                        >
                          <span>Términos y condiciones</span>
                        </a>
                      </p>
                    }
                    name="termAndConditions"
                    boxcolor="primary"
                  />
                </Col>
                <Col lg={6} className="pb-2 pb-lg-0">
                  <InputCheckbox
                    label={
                      <p>
                        Acepto compartir legajos de Allaria con Allaria+?{" "}
                        <span onClick={handleClickDetail}>Ver detalle</span>
                      </p>
                    }
                    name="allariaFileSharing"
                    boxcolor="primary"
                  />
                </Col>
              </Row>

              <Row className="px-2 py-4">
                <Button
                  buttonType="primary"
                  text="Iniciar apertura"
                  type="submit"
                  width="100%"
                  disabled={!(dirty && isValid) || loadingButton}
                  isLoading={loadingButton}
                />
              </Row>
            </Form>
          )}
        </FormikWrapper>
      ) : (
        <FormikWrapper
          initialValues={initialValuesFormik}
          validationSchema={stepOneAllariaSchema}
          onSubmit={handleSubmit}
        >
          {({ values, isValid, dirty, errors, touched }) => (
            <Form>
              <Row>
                <Col lg={6} className="pb-2 pb-lg-0">
                  <InputTextNumber
                    label="Email personal"
                    name="personalMail"
                    type="text"
                    placeholder="nombre@mail.com"
                  />
                </Col>
                <Col lg={6} className="pb-2 pb-lg-0">
                  <InputTextNumber
                    label="Mi mail registrado en Allaria es"
                    name="allariaMail"
                    type="text"
                    placeholder="nombre@mail.com"
                  />
                </Col>
              </Row>
              <Row className="mt-md-3">
                <Col lg={6} className="pb-2 pb-lg-0">
                  <InputTextNumber
                    label="Nombre/s"
                    name="firstName"
                    type="text"
                    placeholder=""
                  />
                </Col>
                <Col lg={6} className="pb-2 pb-lg-0">
                  <InputTextNumber
                    label="Apellido/s"
                    name="lastName"
                    type="text"
                    placeholder=""
                  />
                </Col>
              </Row>
              <Row
                className={`mt-md-3 d-flex ${
                  values.userReferred
                    ? "align-items-start"
                    : "align-items-center"
                }`}
              >
                <Col lg={6} className="pb-2 pb-lg-0">
                  <InputTextNumber
                    name="numberId"
                    label="CUIT, CUIL"
                    placeholder="Sin puntos"
                    type="number"
                  />
                </Col>
                <Col lg={6} className="pb-2 pb-lg-0">
                  <InputCheckbox
                    label="Fui referido"
                    name="userReferred"
                    boxcolor="lightblue"
                  />
                  {values.userReferred && (
                    <div className={referredInput}>
                      <InputTextNumber name="emailOrNameReferred" type="text" />
                      <div
                        className={
                          errors.emailOrNameReferred &&
                          touched.emailOrNameReferred
                            ? referredSpanErr
                            : referredSpan
                        }
                      >
                        <span>
                          Ingresá nombre o mail de la persona que te refirió
                        </span>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
              <Row className={`mt-5 ${checkBox}`}>
                <Col lg={6} className="pb-2 pb-lg-0">
                  <InputCheckbox
                    label={
                      <p>
                        Acepto los{" "}
                        <a
                          href={`${process.env.REACT_APP_STATICURL}/terms/2023-07-11/TyC+Allaria%2B+Cuenta+con+Anexo+I+sin+firma.pdf`}
                          target="_blank"
                          rel="noreferrer"
                          className="text-decoration-none"
                        >
                          <span>Términos y condiciones</span>
                        </a>
                      </p>
                    }
                    name="termAndConditions"
                    boxcolor="primary"
                  />
                </Col>
                <Col lg={6} className="pb-2 pb-lg-0">
                  <InputCheckbox
                    label={
                      <p>
                        Acepto compartir legajos de Allaria con Allaria+?{" "}
                        <span onClick={handleClickDetail}>Ver detalle</span>
                      </p>
                    }
                    name="allariaFileSharing"
                    boxcolor="primary"
                  />
                </Col>
              </Row>

              <Row className="px-2 py-4">
                <Button
                  buttonType="primary"
                  text="Iniciar apertura"
                  type="submit"
                  width="100%"
                  disabled={!(dirty && isValid) || loadingButton}
                  isLoading={loadingButton}
                />
              </Row>
            </Form>
          )}
        </FormikWrapper>
      )}
    </Container>
  );
};

export default StepOneAllaria;
