import React, { MouseEventHandler } from "react";
import Tippy from "@tippyjs/react";
import styles from "./Tooltip.module.scss";
import { Placement } from "react-bootstrap/esm/types";

interface TooltipProps {
  text: string | React.ReactElement;
  children: React.ReactElement;
  position: Placement | undefined;
  clickHandler?: MouseEventHandler;
  interactive?: boolean;
}

const Tooltip = (props: TooltipProps) => {
  const { text, children, position, clickHandler, interactive } = props;
  const { TextLink, Text, ContainerTooltip } = styles;

  return (
    <Tippy
      interactive={interactive || false}
      maxWidth={300}
      trigger="mouseenter focus, click"
      placement={position}
      theme="white-border"
      offset={[0, 20]}
      content={
        <div className={ContainerTooltip}>
          <div
            className={clickHandler ? TextLink : Text}
            onClick={clickHandler && clickHandler}
          >
            {text}
          </div>
        </div>
      }
    >
      {children}
    </Tippy>
  );
};

export default Tooltip;
