import { useCallback, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as CloseIcon } from "../../../assets/img/closeIcon.svg";
import styles from "./modal.module.scss";
import { RootState } from "../../../app/store";

interface ModalProps {
    onClose: () => void;
    children: React.ReactNode;
    modalRef?: React.Ref<HTMLDivElement> | undefined;
    closeIcon?: {
        hide: boolean;
        position?: [number, number];
    }
}

const backdrop = {
  visible: {  opacity: 1, transition: { when: "beforeChildren" }},
  hidden: { opacity: 0, transition: { when: "afterChildren", delay: 0.2 }},
};

const modalContent = {
  hidden: { opacity: 0,
    transition: { duration: 0.1 }
  },
  visible: { opacity: 1,
    transition: { duration: 0.1 }
  },
};

const modal = {
  hidden : {
    opacity: 0,
    transition: {
      when: "afterChildren",
      duration: 0.2,
    },
    y: -150
  },
  visible : {
    opacity: 1,
    transition: {
      duration: 0.2,
    },
    y : 0,
  }
};


const Modal = ({ onClose, children, modalRef, closeIcon } : ModalProps) => {
  const dispatch = useDispatch();
  const {modalOpen} = useSelector((state: RootState) => state.modal);
  
  const escFunction = useCallback((event: KeyboardEvent) => {
    const key = event.key || event.keyCode;
    if (!closeIcon?.hide && (key === 'Escape' || key === 'Esc' || key === 27)) onClose();
  }, []);

  useEffect(() => {
    if( modalOpen ){
      // Prevent scroll when modal is open
      document.body.classList.add("overflow-hidden");
      //Close the modal by pressing esc key
      document.addEventListener("keydown", escFunction);

    } else {
      document.body.classList.remove("overflow-hidden");
      document.removeEventListener("keydown", escFunction);
    }
    return () => {
      document.body.classList.remove("overflow-hidden");
      document.removeEventListener("keydown", escFunction);
    };

  }, [ modalOpen, escFunction, dispatch ]);

  return (
    <AnimatePresence>
      { modalOpen && (
        <motion.div className={styles.backdrop}
          variants={ backdrop }
          initial="hidden"
          animate="visible"
          exit="hidden"
          ref={modalRef}
        >
          <div className={styles.modalWrapper}>
              <motion.div className={styles.modalCard}
                variants={modal}
                initial={"hidden"}
                animate="visible"
                exit="hidden"
                layout
                transition={{
                  layout: { duration: 0.3, ease: [ 0.4, 0.15, 0.25, 0.9 ]},
                }}
              >
                { (!closeIcon || !closeIcon.hide) && 
                <CloseIcon onClick={onClose} onMouseDown={(e) => e.preventDefault()} 
                    className={styles.closeIcon}
                    style={{
                      top: closeIcon?.position?.[0] ?? 32,
                      right: closeIcon?.position?.[1] ?? 32,
                    }}
                />
                }
                <motion.div variants={modalContent} layout="position" className={styles.contentWrapper}>
                  {children}
                </motion.div>
              </motion.div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
export default Modal;