import React, { ReactNode } from "react";
import { AnimatePresence, motion } from "framer-motion";
import styles from "./transitionStyles.module.scss";

interface Props {
  animationDirection: 1 | -1;
  children: ReactNode;
  title?:boolean
  currentStep?:any
}

const variants = {
  enter: (direction: number) => {
    return {
      y: direction > 0 ? 100 : -100,
      opacity: 0,
    };
  },
  center: {
    y: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      y: direction < 0 ? 100 : -100,
      opacity: 0,
    };
  },
};

const TransitionStepsWrapper = ({ animationDirection, children, title, currentStep }: Props) => {
  return (
    <motion.div style={{ overflow: "hidden" }}>
      <AnimatePresence exitBeforeEnter>
      <motion.div
        custom={animationDirection}
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{
          type: "tween",
          duration: 0.5,
        }}
        className={title?styles.contentBis:styles.content}
        key={currentStep}
      >
        {children}
      </motion.div>
      </AnimatePresence>
    </motion.div>
  );
};

export default TransitionStepsWrapper;
