import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  localStorageLoaded: false,
};

const localStorageSlice = createSlice({
  name: "localStorage",
  initialState,
  reducers: {
    updateLocalStorageLoaded: (state) => {
      state.localStorageLoaded = true;
    },
  }
});

export const { updateLocalStorageLoaded } = localStorageSlice.actions;

export default localStorageSlice.reducer;