import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import reportWebVitals from "./reportWebVitals";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import "./index.scss";
import "./assets/css/tooltip-themes.css";
import "tippy.js/dist/tippy.css";
import "tippy.js/dist/border.css";
import FormPage from "./components/FormPage/FormPage";
import DNIUpload from "./components/DNIUpload/DNIUpload";
import ErrorPage from "./components/ErrorPage/ErrorPage";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { ThemeProvider } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import LivenessValidationCard from "./components/FormPage/Steps/StepThree/LivenessValidationCard/LivenessValidationCard";
import { Amplify } from "aws-amplify";
import awsexports from "./aws-exports";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_authDomain,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

getStorage(app);
getFirestore(app);

const container = document.getElementById("root")!;
const root = createRoot(container);
export const persistor = persistStore(store);

// Liveness Config
Amplify.configure(awsexports);

const router = createBrowserRouter([
  {
    path: "*",
    element: <FormPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/dni-upload",
    element: <DNIUpload />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/liveness-validation",
    element: <LivenessValidationCard />,
    errorElement: <ErrorPage />,
  },
]);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider>
        <PersistGate loading={null} persistor={persistor}>
          <RouterProvider router={router} />
        </PersistGate>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
