import { CSSProperties } from "react";
import { ToastContainer, ToastContainerProps, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface ToastMessageProps extends ToastContainerProps {
    messageId: string;
    width?: string;
    style?: CSSProperties;
}

const ToastMessage = (props: ToastMessageProps) => {
  const { messageId, width, style,  ...rest } = props;
  return(<>
    <ToastContainer
      position="top-center"
      enableMultiContainer
      containerId={messageId}
      transition={Slide}
      style={{
        position: "initial",
        transform: "none",
        width: width || "100%",
        fontSize: "0.875rem",
        ...style
      }}
      {...rest}
    />
    <ToastContainer
      position="top-center"
      enableMultiContainer
      containerId={`${messageId}-overPage`}
      transition={Slide}
      style={{ width: width || "auto", fontSize: "0.875rem", ...style }}
      {...rest}
    />
  </>
  );
};

export default ToastMessage;