import * as Yup from "yup";
import {
  emailRequired,
  string,
  stringRequired,
  boolean,
  mustBeTrue,
  fileRequired,
  cbuRequired,
  cuitRequired,
} from "./utils/validations";

//Step one
export const stepOneAllariaSchema = Yup.object().shape({
  personalMail: emailRequired,
  allariaMail: emailRequired,
  firstName: stringRequired,
  lastName: stringRequired,
  numberId: cuitRequired,
  userReferred: boolean,
  emailOrNameReferred: string.when("userReferred", {
    is: true,
    then: stringRequired,
  }),
  termAndConditions: mustBeTrue,
  allariaFileSharing: mustBeTrue,
});

export const stepOneJuridicalAllariaSchema = Yup.object().shape({
  email: emailRequired,
  allariaEmail: emailRequired,
  businessName: stringRequired,
  businessCuit: stringRequired.length(11, "Deben ser 11 caracteres"),
  representativeName: stringRequired,
  representativeLastName: stringRequired,
  representativeDni: stringRequired
    .min(7, "Deben ser entre 7 y 8 caracteres")
    .max(8, "Deben ser entre 7 y 8 caracteres"),
  representativeTel: stringRequired.min(5, "Ingresar número válido."),
  userReferred: boolean,
  emailOrNameReferred: string.when("userReferred", {
    is: true,
    then: stringRequired,
  }),
  termAndConditions: mustBeTrue,
  allariaFileSharing: mustBeTrue,
  hasJointSignature: boolean,
  companyType: stringRequired,
  publicEntity: boolean,
});

export const stepOneSchema = Yup.object().shape({
  email: emailRequired,
  userReferred: boolean,
  emailOrNameReferred: string.when("userReferred", {
    is: true,
    then: stringRequired,
  }),
});

// Step three
export const stepThreePart1Schema = Yup.object().shape({
  dni: stringRequired
    .min(7, "Deben ser entre 7 y 8 caracteres")
    .max(8, "Deben ser entre 7 y 8 caracteres"),
  processNumber: stringRequired.length(
    11,
    "Ingresá los 11 caracteres que figuran en el DNI"
  ),
  gender: stringRequired,
  maritalStatus: stringRequired,
  frontDNI: fileRequired,
  backDNI: fileRequired,
});

export const stepThreePart3Schema = Yup.object().shape({
  firstName: stringRequired,
  lastName: stringRequired,
  dateOfBirth: stringRequired,
  nationality: stringRequired,
  cuit: stringRequired.length(11, "Deben ser 11 caracteres"),
  telephoneNumber: stringRequired.min(5, "Ingresar número válido."),
  politicallyExposedPerson: stringRequired,
  street: stringRequired,
  streetNumber: stringRequired,
  floorNumber: string,
  department: string,
  zipCode: stringRequired,
  locality: stringRequired,
  province: stringRequired,
});

//Step four
export const stepFourSchema = Yup.object().shape({
  dependencyRelationship: stringRequired,
  activityCategory: stringRequired,
  taxedOutsideTheCountry: stringRequired,
  countryOfTaxation: string.when("taxedOutsideTheCountry", {
    is: "YES",
    then: stringRequired,
  }),
  NIF: string.when("taxedOutsideTheCountry", {
    is: "YES",
    then: stringRequired,
  }),
  hasOpenBankAccount: stringRequired,
  CBU: string.when("hasOpenBankAccount", {
    is: "YES",
    then: cbuRequired,
  }),
});

//Step five
export const stepFiveSchema = Yup.object().shape({
  capitalMarketKnowledge: stringRequired,
  investmentObjective: stringRequired,
  investmentTerm: stringRequired,
  percentageOfSavingsToBeInvested: stringRequired,
  investmentRisk: stringRequired,
  sourceOfFunds: stringRequired,
  descriptionOfSourceFunds: string.when("sourceOfFunds", {
    is: (sourceOfFunds: string) => sourceOfFunds === "otros",
    then: stringRequired,
    otherwise: string,
  }),
  monthlyIncome: stringRequired,
  totalEquity: stringRequired,
  termAndConditions: mustBeTrue,
});
