import { useEffect, useRef, useState } from "react";
import InputFile from "../ui/Inputs/InputFile";
import FormikWrapper from "../ui/FormikWrapper/FormikWrapper";
import Button from "../ui/Button/Button";
import { Row, Col } from "react-bootstrap";
import { fileNotRequired } from "../../schemas/utils/validations";
import * as Yup from "yup";
import { getStorage, ref, getBlob } from "firebase/storage";
import { doc, onSnapshot, getFirestore } from "firebase/firestore";
import {
  useSearchParams,
  useNavigate,
  createSearchParams,
} from "react-router-dom";
import {
  FirebaseUserImageState,
  FirebaseUserImage,
  DNIImageType,
} from "../../interfaces/types";
import styles from "./DNIUpload.module.scss";
import successImage from "../../assets/img/ready.svg";

interface DNIUploadValues {
  frontDNI: any | null | number;
  backDNI: any | null | number;
}

export const stepThreePart1Schema = Yup.object().shape({
  frontDNI: fileNotRequired,
  backDNI: fileNotRequired,
});

type Props = {};

const initialValues: DNIUploadValues = {
  frontDNI: null,
  backDNI: null,
};

const DNIUpload = (props: Props) => {
  const formikForm = useRef<any>(null);
  let [searchParams] = useSearchParams();

  const urlToken = searchParams.get("token");
  const urlSuccess = searchParams.get("success");

  const db = getFirestore();
  const storage = getStorage();

  const navigate = useNavigate();

  const [firebaseFiles, setFirebaseFiles] = useState<FirebaseUserImageState>({
    frontDNI: {
      uploaded: undefined,
      storageFullPath: undefined,
      file: undefined,
      originalFileName: undefined,
    },
    backDNI: {
      uploaded: undefined,
      storageFullPath: undefined,
      file: undefined,
      originalFileName: undefined,
    },
  });

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "userImages", urlToken || ""), (doc) => {
      if (doc.exists()) {
        const data = doc.data() as FirebaseUserImage;

        Object.keys(data).forEach((dniImage) => {
          const imageDNI = data[dniImage as DNIImageType];
          const imageRef = ref(storage, imageDNI.storageFullPath);

          if (imageDNI.storageFullPath) {
            getBlob(imageRef)
              .then((blob) => {
                const blobToFile = new File(
                  [blob],
                  imageDNI.originalFileName || "",
                  {
                    type: blob.type,
                  }
                );
                setFirebaseFiles((prevState) => ({
                  ...prevState,
                  [dniImage]: {
                    ...imageDNI,
                    file: blobToFile,
                  },
                }));
                if (formikForm?.current) {
                  formikForm.current.setFieldValue(dniImage, blobToFile);
                  formikForm.current.setFieldTouched(dniImage, true);
                }
              })
              .catch((err) => {
                console.log("err", err);
              });
          }
        });
      }
    });

    return unsub;
  }, []);

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <>
      {!urlSuccess ? (
        <div className={styles.dniUpload}>
          <FormikWrapper
            initialValues={initialValues}
            onSubmit={(values) => {
              console.log(values);
            }}
            validationSchema={stepThreePart1Schema}
            innerRef={formikForm}
          >
            {({ errors, handleSubmit, values }) => (
              <form>
                <h3>Cargá las fotos de tu DNI</h3>
                <Row>
                  <Col md className="mb-4">
                    <label>Frente</label>
                    <InputFile
                      name="frontDNI"
                      token={urlToken || ""}
                      uploadedFile={
                        firebaseFiles.frontDNI?.uploaded
                          ? firebaseFiles.frontDNI?.file
                          : null
                      }
                    />
                  </Col>
                  <Col md className="mb-4">
                    <label>Dorso</label>
                    <InputFile
                      name="backDNI"
                      token={urlToken || ""}
                      uploadedFile={
                        firebaseFiles.backDNI?.uploaded
                          ? firebaseFiles.backDNI?.file
                          : null
                      }
                    />
                  </Col>
                </Row>
              </form>
            )}
          </FormikWrapper>
          <Button
            click={() =>
              navigate({
                search: createSearchParams({
                  token: urlToken || "",
                  success: "true",
                }).toString(),
              })
            }
            className={styles.readyButton}
            buttonType="primary"
            text="Listo"
            type="button"
            width="100%"
          />
        </div>
      ) : (
        <div className={styles.successPage}>
          <img src={successImage} alt="Listo" />
          <h2>
            ¡Listo! ya podés cerrar esta página y seguir en tu computadora.
          </h2>
        </div>
      )}
    </>
  );
};

export default DNIUpload;
